import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import notiicon from "assets/NotificationIcon.png";
import deleteimg from "assets/delete.png";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import cameraimg from "assets/camera.png";
import edit from "assets/edit.png";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ExpandMore } from "@material-ui/icons";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  headertitle: {
    marginBottom: "0",
    marginLeft: "40px",
    color: "#000",
    fontWeight: "400",
  },
  notificationtitle: {
    marginTop: "0",
    fontSize: "15px",
    marginLeft: "42px",
    color: "#000",
    fontWeight: "400",
  },

  gridcontainer: {
    backgroundColor: "#eeeeee",
    height: "498px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardui: {
    textAlign: "center",
    margin: "none",
  },
  cardheader: {
    fontSize: "70px",
    marginBottom: "0",
    fontWeight: "900",
    color: "#F8A11C",
  },
  cardheadermedile: {
    fontSize: "70px",
    marginBottom: "0",
    fontWeight: "900",
    color: "#000",
  },
  carddesc: {
    marginTop: "0",
    marginBottom: "30px",
  },
  reporttag: {
    backgroundColor: "#eee",
    color: "#000",
    marginBottom: "0",
    boxShadow: "unset",
    width: "200px",
    marginLeft: "75px",
    marginTop: "65px",
    textAlign: "center",
    padding: "10px",
  },
  selectui: {
    position: "relative",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "36px",
    height: "30px",
    bottom: "-65px",
    width: "200px",
    textAlign: "center",
    border: "solid 2px #CDD1D4",
    padding: "20px",
  },
  notiicon: {
    width: "10px",
    marginRight: "5px",
  },
  customers: {
    fontFamily: "Arial, Helvetica, sans-serif",
    borderCollapse: "collapse",
    width: "100%",
  },
  customerstd: {
    border: "1px solid #ddd",
    padding: "8px",
  },
  tableUpgradeWrapper: {
    width: "100%",
    // display: "block",
    overflowX: "auto",
    backgroundColor: "#eee",
    MsOverflowStyle: "-ms-autohiding-scrollbar",
    WebkitOverflowScrolling: "touch",
    display: "flex",
    flexDirection: "column",
    padding: "2%",
  },
  table: {
    width: "100%",
    display: "table",
    marginTop: "4%",
    overflowX: "auto",
    borderColor: "grey",
    borderSpacing: "2px",
    borderCollapse: "collapse",
    backgroundColor: "white",
    "& thdead tr th": {
      fontSize: "1.063rem",
      padding: "12px 8px",
      verticalAlign: "middle",
      fontWeight: "300",
      borderTopWidth: "0",
      borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
      textAlign: "inherit",
    },
    "& tbody tr td": {
      padding: "12px 8px",
      verticalAlign: "middle",
      borderTop: "1px solid rgba(0, 0, 0, 0.06)",
    },
    "& td, & th": {
      display: "table-cell",
    },
  },
  center: {
    textAlign: "center",
  },
  colortd: {
    backgroundColor: "#CDD1D4",
    textAlign: "center",
    padding: "10px",
    whiteSpace: "nowrap",
  },
  imgwidth: {
    width: "19%",
    marginRight: "5px",
  },
  dropdownimg: {
    width: "37px",
    position: "relative",
    top: "2px",
    cursor: "pointer",
  },
  textright: {
    textAlign: "right",
  },
  divright: {
    textAlign: "right",
    marginBottom: "50px",
  },
  diplayblock: {
    display: "block",
  },
  displayinlineblock: {
    display: "inline-block",
    margin: "10px",
  },
  marginstyle: {
    margin: "0",
    fontWeight: "bold",
  },
  statestylelabel: {
    marginLeft: "10px",
  },
  inputstyle: {
    width: "288px",
    padding: "10px",
  },
  textareastyle: {
    width: "90%",
    marginLeft: "10px",
  },
  textareamargin: {
    marginLeft: "10px",
    margin: "0",
    fontWeight: "bold",
  },
  statestyle: {
    width: "145px",
    padding: "10px",
  },
  zipcodestyle: {
    width: "110px",
    padding: "9px",
  },
  btnupdate: {
    backgroundColor: "#F8A11C",
    marginRight: "39px",
    marginTop: "5%",
    border: "none",
    padding: "10px",
    width: "120px",
    color: "#fff",
    borderRadius: "5px",
  },
  textaligncenter: {
    textAlign: "center",
  },
  dialogtextaligncenter: {
    textAlign: "center",
    padding: "20px",
  },
  cancelbtn: {
    position: "relative",
    padding: "13px",
    width: "110px",
    marginRight: "10px",
    color: "#fff",
    backgroundColor: "#cdd1d4",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  deletebtn: {
    position: "relative",
    padding: "13px",
    width: "110px",
    marginRight: "10px",
    color: "#fff",
    backgroundColor: "#fd0e0e",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  sendbtn: {
    position: "relative",
    padding: "13px",
    width: "110px",
    marginRight: "10px",
    color: "#fff",
    backgroundColor: "#ff9800",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  textalignbtn: {
    textAlign: "center",
    width: "100%",
  },
  addbtn: {
    color: "#fff",
    width: "200px",
    border: "none",
    height: "42px",
    display: "block",
    marginTop: "20px",
    backgroundColor: "#ff9800",
    borderRadius: "5px",
    cursor: "pointer",
  },
  registertextfield: {
    margin: "0 auto",
    display: "block",
    padding: "10px",
    marginBottom: "19px",
    width: "75%",
  },
  tabletitle: {
    position: "absolute",
    left: "6.5%",
    fontSize: "21px",
    marginTop: "36px",
    fontWeight: "bold",
  },
  cameraimage: {
    width: "72px",
  },
  displycss: {
    display: "inline-block",
  },
  editimage: {
    width: "20px",
    position: "relative",
    top: "0px",
    left: "8px",
    cursor: "pointer",
  },
  imagephoto: {
    width: "180px",
    position: "relative",
    top: "13px",
  },
  searchbox: {
    width: "200px",
    border: "none",
    padding: "11px",
    fontSize: "14px",
    marginTop: "22px",
    borderRadius: "39px",
  },
  filterbtnbox: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  uploadImgWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    paddingBottom: "15px",
  },
  "@media(max-width:600px)": {
    filterbtnbox: {
      flexDirection: "column",
      alignItems: "center",
    },
    notificationtitle: {
      textAlign: "center",
      marginLeft: "0px",
    },
    headertitle: {
      textAlign: "center",
      marginLeft: "0px",
    },
  },
};

const useStyles = makeStyles(styles);

export default function modalconfigratorsetting() {
  const url = "https://bathroombackend.instantsolutionslab.site/api/";
  const classes = useStyles();

  const [expandedPanel, setExpandedPanel] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [openbtn, setbtnOpen] = React.useState(false);
  const [openbtnedit, setbtnOpenedit] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [BathroomModel, setBathroomModel] = React.useState([]);
  const [editid, seteditid] = React.useState();
  const [bathroommodalname, setbathroommodalname] = React.useState("");
  const [bathroommodalprice, setbathroommodalprice] = React.useState("");
  const [bathroommodalimage, setbathroommodalimage] = React.useState("");
  const [bathroommodalisdefault, setbathroommodalisdefault] = React.useState(1);
  const [opentoast, setOpentoast] = React.useState(false);
  const [deleteid, setdeleteid] = React.useState("");
  const [checked, setChecked] = React.useState(true);
  const [loader, setLoader] = React.useState(false);
  /*Bathroom shapes useStates */
  const [bathroomShapeOpen, setBathroomShapeOpen] = React.useState(false);
  const [bathroomShapeOpenBtn, setBathroomShapeOpenBtn] = React.useState(false);
  const [
    bathroomShapeOpenBtnEdit,
    setBathroomShapeBtnOpenEdit,
  ] = React.useState(false);
  const [bathroomShapeMessage, setBathroomShapeMessage] = React.useState("");
  const [bathroomShape, setBathroomShape] = React.useState([]);
  const [bathroomShapeEditid, setBathroomShapeEditid] = React.useState();
  const [bathroomShapeName, setBathroomShapeName] = React.useState("");
  const [bathroomShapePrice, setBathroomShapePrice] = React.useState("");
  const [bathroomShapeImage, setBathroomShapeImage] = React.useState("");
  const [bathroomShapeIsDefault, setBathroomShapeIsDefault] = React.useState(1);
  const [bathroomShapeOpenToast, setBathroomShapeOpenToast] = React.useState(
    false
  );
  const [bathroomShapeDeleteId, setBathroomShapeDeleteId] = React.useState("");
  const [bathroomShapeChecked, setBathroomShapeChecked] = React.useState(true);

  /*Floor Plan useStates */
  const [floorPlanOpen, setFloorPlanOpen] = React.useState(false);
  const [floorPlanOpenBtn, setFloorPlanOpenBtn] = React.useState(false);
  const [floorPlanOpenBtnEdit, setFloorPlanBtnOpenEdit] = React.useState(false);
  const [floorPlanMessage, setFloorPlanMessage] = React.useState("");
  const [floorPlan, setFloorPlan] = React.useState([]);
  const [floorPlanEditid, setFloorPlanEditid] = React.useState();
  const [floorPlanName, setFloorPlanName] = React.useState("");
  const [floorPlanPrice, setFloorPlanPrice] = React.useState("");
  const [floorPlanImage, setFloorPlanImage] = React.useState("");
  const [floorPlanIsDefault, setFloorPlanIsDefault] = React.useState(1);
  const [floorPlanOpenToast, setFloorPlanOpenToast] = React.useState(false);
  const [floorPlanDeleteId, setFloorPlanDeleteId] = React.useState("");
  const [floorPlanChecked, setFloorPlanChecked] = React.useState(true);

  /*Vanity Color useStates */

  const [vanityColorOpen, setVanityColorOpen] = React.useState(false);
  const [vanityColorOpenBtn, setVanityColorOpenBtn] = React.useState(false);
  const [vanityColorOpenBtnEdit, setVanityColorBtnOpenEdit] = React.useState(
    false
  );
  const [vanityColorMessage, setVanityColorMessage] = React.useState("");
  const [vanityColor, setVanityColor] = React.useState([]);
  const [vanityColorEditid, setVanityColorEditid] = React.useState();
  const [vanityColorName, setVanityColorName] = React.useState("");
  const [vanityColorPrice, setVanityColorPrice] = React.useState("");
  const [vanityColorCode, setVanityColorCode] = React.useState("");
  const [vanityColorIsDefault, setVanityColorIsDefault] = React.useState(1);
  const [vanityColorOpenToast, setVanityColorOpenToast] = React.useState(false);
  const [vanityColorDeleteId, setVanityColorDeleteId] = React.useState("");
  const [vanityColorChecked, setVanityColorChecked] = React.useState(true);

  /*Wall Color useStates */

  const [wallColorOpen, setWallColorOpen] = React.useState(false);
  const [wallColorOpenBtn, setWallColorOpenBtn] = React.useState(false);
  const [wallColorOpenBtnEdit, setWallColorBtnOpenEdit] = React.useState(false);
  const [wallColorMessage, setWallColorMessage] = React.useState("");
  const [wallColor, setWallColor] = React.useState([]);
  const [wallColorEditid, setWallColorEditid] = React.useState();
  const [wallColorName, setWallColorName] = React.useState("");
  const [wallColorPrice, setWallColorPrice] = React.useState("");
  const [wallColorCode, setWallColorCode] = React.useState("");
  const [wallColorIsDefault, setWallColorIsDefault] = React.useState(1);
  const [wallColorOpenToast, setWallColorOpenToast] = React.useState(false);
  const [wallColorDeleteId, setWallColorDeleteId] = React.useState("");
  const [wallColorChecked, setWallColorChecked] = React.useState(true);

  /*Floor Color useStates */

  const [floorColorOpen, setFloorColorOpen] = React.useState(false);
  const [floorColorOpenBtn, setFloorColorOpenBtn] = React.useState(false);
  const [floorColorOpenBtnEdit, setFloorColorBtnOpenEdit] = React.useState(
    false
  );
  const [floorColorMessage, setFloorColorMessage] = React.useState("");
  const [floorColor, setFloorColor] = React.useState([]);
  const [floorColorEditid, setFloorColorEditid] = React.useState();
  const [floorColorName, setFloorColorName] = React.useState("");
  const [floorColorPrice, setFloorColorPrice] = React.useState("");
  const [floorColorCode, setFloorColorCode] = React.useState("");
  const [floorColorIsDefault, setFloorColorIsDefault] = React.useState(1);
  const [floorColorOpenToast, setFloorColorOpenToast] = React.useState(false);
  const [floorColorDeleteId, setFloorColorDeleteId] = React.useState("");
  const [floorColorChecked, setFloorColorChecked] = React.useState(true);

  /*Accent Color useStates */
  const [accentColorOpen, setAccentColorOpen] = React.useState(false);
  const [accentColorOpenBtn, setAccentColorOpenBtn] = React.useState(false);
  const [accentColorOpenBtnEdit, setAccentColorBtnOpenEdit] = React.useState(
    false
  );
  const [accentColorMessage, setAccentColorMessage] = React.useState("");
  const [accentColor, setAccentColor] = React.useState([]);
  const [accentColorEditid, setAccentColorEditid] = React.useState();
  const [accentColorName, setAccentColorName] = React.useState("");
  const [accentColorPrice, setAccentColorPrice] = React.useState("");
  const [accentColorCode, setAccentColorCode] = React.useState("");
  const [accentColorIsDefault, setAccentColorIsDefault] = React.useState(1);
  const [accentColorOpenToast, setAccentColorOpenToast] = React.useState(false);
  const [accentColorDeleteId, setAccentColorDeleteId] = React.useState("");
  const [accentColorChecked, setAccentColorChecked] = React.useState(true);

  const [userinfo, setUserinfo] = React.useState("");
  React.useEffect(() => {
    setExpandedPanel("panel1");
    setUserinfo(JSON.parse(localStorage.getItem("logininfo")));
    console.log(userinfo);
  }, []);

  const handleswitchAccentColor = (event) => {
    console.log(event.target.checked);
    setAccentColorChecked(event.target.checked);
    if (accentColorChecked == true) {
      setAccentColorIsDefault(0);
    } else {
      setAccentColorIsDefault(1);
    }
  };
  const handleswitchWallColor = (event) => {
    console.log(event.target.checked);
    setWallColorChecked(event.target.checked);
    if (wallColorChecked == true) {
      setWallColorIsDefault(0);
    } else {
      setWallColorIsDefault(1);
    }
  };
  const handleswitchVanityColor = (event) => {
    console.log(event.target.checked);
    setVanityColorChecked(event.target.checked);
    if (vanityColorChecked == true) {
      setVanityColorIsDefault(0);
    } else {
      setVanityColorIsDefault(1);
    }
  };
  const handleswitchFloorColor = (event) => {
    console.log(event.target.checked);
    setFloorColorChecked(event.target.checked);
    if (floorColorChecked == true) {
      setFloorColorIsDefault(0);
    } else {
      setFloorColorIsDefault(1);
    }
  };

  const handleswitchBathroomShapes = (event) => {
    console.log(event.target.checked);
    setBathroomShapeChecked(event.target.checked);
    if (bathroomShapeChecked == true) {
      setBathroomShapeIsDefault(0);
    } else {
      setBathroomShapeIsDefault(1);
    }
  };

  const handleswitchFloorPlan = (event) => {
    console.log(event.target.checked);
    setFloorPlanChecked(event.target.checked);
    if (floorPlanChecked == true) {
      setFloorPlanIsDefault(0);
    } else {
      setFloorPlanIsDefault(1);
    }
  };

  const handleswitch = (event) => {
    console.log(event.target.checked);
    // console.log(checked);
    // console.log(event.target.checked);
    setChecked(event.target.checked);
    if (checked == true) {
      setbathroommodalisdefault(0);
    } else {
      setbathroommodalisdefault(1);
    }
  };
  const handleClickOpen = (valId, callType) => {
    if (callType == "bathroomModelType") {
      console.log(callType + " " + valId);
      setdeleteid(valId);
      setOpen(true);
    } else if (callType == "bathroomShape") {
      setBathroomShapeDeleteId(valId);
      setBathroomShapeOpen(true);
      console.log(callType + " " + valId);
    } else if (callType == "floorPlan") {
      setFloorPlanDeleteId(valId);
      setFloorPlanOpen(true);
      console.log(callType + " " + valId);
    } else if (callType == "wallColor") {
      setWallColorDeleteId(valId);
      setWallColorOpen(true);
      console.log(callType + " " + valId);
    } else if (callType == "vanityColor") {
      setVanityColorDeleteId(valId);
      setVanityColorOpen(true);
      console.log(callType + " " + valId);
    } else if (callType == "accentColor") {
      setAccentColorDeleteId(valId);
      setAccentColorOpen(true);
      console.log(callType + " " + valId);
    } else if (callType == "floorColor") {
      setFloorColorDeleteId(valId);
      setFloorColorOpen(true);
      console.log(callType + " " + valId);
    }
  };
  const [new1, setnew1] = React.useState("");

  const handleImageFloorPlan = (event) => {
    const file = event.target.files[0];
    setnew1(file);
    if (file) {
      setFloorPlanImage(file);
    }
  };
  const [new2, setnew2] = React.useState("");
  const handleBathroomModelImage = (event) => {
    const file = event.target.files[0];
    setnew2(file);
    if (file) {
      setbathroommodalimage(file);
    }
  };
  const [new3, setnew3] = React.useState("");

  const handleBathroomShapeImage = (e) => {
    const file = e.target.files[0];
    setnew3(file);

    if (file) {
      setBathroomShapeImage(file);
    }
  };

  const handleClickOpenedit = (param, callType) => {
    console.log(param + "sdfs" + callType);
    if (callType == "bathroomModelType") {
      // console.log(param);
      // console.log(param.is_default);
      seteditid(param.id);
      setbathroommodalname(param.name);
      setbathroommodalprice(param.price);
      setbathroommodalimage(param.photo);
      if (param.is_default == "1") {
        setChecked(true);
      } else {
        setChecked(false);
      }
      setbtnOpenedit(true);
    } else if (callType == "bathroomShape") {
      console.log(param);
      setBathroomShapeEditid(param.id);
      setBathroomShapeName(param.name);
      setBathroomShapePrice(param.price);
      setBathroomShapeImage(param.icon);
      if (param.is_default == "1") {
        setBathroomShapeChecked(true);
      } else {
        setBathroomShapeChecked(false);
      }
      setBathroomShapeBtnOpenEdit(true);
    } else if (callType == "floorPlan") {
      console.log(param);
      setFloorPlanEditid(param.id);
      setFloorPlanName(param.name);
      setFloorPlanPrice(param.price);
      setFloorPlanImage(param.photo);
      if (param.is_default == "1") {
        setFloorPlanChecked(true);
      } else {
        setFloorPlanChecked(false);
      }
      setFloorPlanBtnOpenEdit(true);
    } else if (callType == "vanityColor") {
      console.log(param);
      setVanityColorEditid(param.id);
      setVanityColorName(param.name);
      setVanityColorPrice(param.price);
      setVanityColorCode(param.code);
      if (param.is_default == "1") {
        setVanityColorChecked(true);
      } else {
        setVanityColorChecked(false);
      }
      setVanityColorBtnOpenEdit(true);
    } else if (callType == "floorColor") {
      console.log(param);
      setFloorColorEditid(param.id);
      setFloorColorName(param.name);
      setFloorColorPrice(param.price);
      setFloorColorCode(param.code);
      if (param.is_default == "1") {
        setFloorColorChecked(true);
      } else {
        setFloorColorChecked(false);
      }
      setFloorColorBtnOpenEdit(true);
    } else if (callType == "wallColor") {
      console.log(param);
      setWallColorEditid(param.id);
      setWallColorName(param.name);
      setWallColorPrice(param.price);
      setWallColorCode(param.code);
      if (param.is_default == "1") {
        setWallColorChecked(true);
      } else {
        setWallColorChecked(false);
      }
      setWallColorBtnOpenEdit(true);
    } else if (callType == "accentColor") {
      console.log(param);
      setAccentColorEditid(param.id);
      setAccentColorName(param.name);
      setAccentColorPrice(param.price);
      setAccentColorCode(param.code);
      if (param.is_default == "1") {
        setAccentColorChecked(true);
      } else {
        setAccentColorChecked(false);
      }
      setAccentColorBtnOpenEdit(true);
    }
    console.log("opened");
  };

  const handleClickbtnOpen = () => {
    seteditid("");
    setbathroommodalname("");
    setbathroommodalprice("");
    setbathroommodalimage("");
    setbtnOpen(true);
  };

  const handleClickbtnOpenBathroomShape = () => {
    setBathroomShapeEditid("");
    setBathroomShapeName("");
    setBathroomShapePrice("");
    setBathroomShapeImage("");
    setBathroomShapeOpenBtn(true);
  };
  const handleClickbtnOpenFloorPlan = () => {
    setFloorPlanEditid("");
    setFloorPlanName("");
    setFloorPlanPrice("");
    setFloorPlanImage("");
    setFloorPlanOpenBtn(true);
  };

  const handleClickbtnOpenAccentColor = () => {
    setAccentColorEditid("");
    setAccentColorName("");
    setAccentColorPrice("");
    setAccentColorCode("");
    setAccentColorOpenBtn(true);
  };

  const handleClickbtnOpenWallColor = () => {
    setWallColorEditid("");
    setWallColorName("");
    setWallColorPrice("");
    setWallColorCode("");
    setWallColorOpenBtn(true);
  };
  const handleClickbtnOpenFloorColor = () => {
    setFloorColorEditid("");
    setFloorColorName("");
    setFloorColorPrice("");
    setFloorColorCode("");
    setFloorColorOpenBtn(true);
  };
  const handleClickbtnOpenVanityColor = () => {
    setVanityColorEditid("");
    setVanityColorName("");
    setVanityColorPrice("");
    setVanityColorCode("");
    setVanityColorOpenBtn(true);
  };

  const handleClickbtnOpenedit = () => {
    setbtnOpenedit(true);
  };

  const handleClose = () => {
    setOpen(false);
    setBathroomShapeOpen(false);
    setFloorPlanOpen(false);
    setFloorColorOpen(false);
    setVanityColorOpen(false);
    setAccentColorOpen(false);
    setWallColorOpen(false);
  };

  const handlebtnClose = () => {
    setbtnOpen(false);
    setBathroomShapeOpenBtn(false);
    setFloorPlanOpenBtn(false);
    setFloorColorOpenBtn(false);
    setVanityColorOpenBtn(false);
    setAccentColorOpenBtn(false);
    setWallColorOpenBtn(false);
  };

  const handlebtnCloseedit = () => {
    setbtnOpenedit(false);
    setBathroomShapeBtnOpenEdit(false);
    setFloorPlanBtnOpenEdit(false);
    setFloorColorBtnOpenEdit(false);
    setVanityColorBtnOpenEdit(false);
    setAccentColorBtnOpenEdit(false);
    setWallColorBtnOpenEdit(false);
  };

  const handleClick = () => {
    setOpentoast(true);
  };

  const handletoastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpentoast(false);
  };
  React.useEffect(() => {
    getBathroomModel();
    getBathroomShapes();
    getFloorPlan();
    getFloorColor();
    getVanityColor();
    getWallColor();
    getAccentColor();
  }, []);

  function getBathroomModel() {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "model-type/get-all", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        if (data.data) {
          setBathroomModel(data.data);
        }
        console.log(BathroomModel);
      })
      .catch(console.log);
  }
  function getBathroomModelsearch(data) {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "model-type/get-all?search=" + data, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        if (data.data) {
          setBathroomModel(data.data);
        }
        console.log(BathroomModel);
      })
      .catch(console.log);
  }

  function AddBathroomModel() {
    setLoader(true);
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    if (
      bathroommodalname != null &&
      bathroommodalprice != null &&
      bathroommodalname != "" &&
      bathroommodalprice != "" &&
      bathroommodalname != undefined &&
      bathroommodalprice != undefined
    ) {
      const data = new FormData();
      data.append("name", bathroommodalname);
      data.append("price", bathroommodalprice);
      data.append("photo", bathroommodalimage);
      data.append("is_default", bathroommodalisdefault);
      fetch(url + "model-type/", {
        method: "POST",
        body: data,
        headers: new Headers({
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoader(false);

          console.log(data);
          if (data.status) {
            setMessage(data.message);
            handleClick();
            handlebtnClose();
            getBathroomModel();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      setMessage("Please fill all fields");
      handleClick();
    }
  }

  function EditBathroomModel() {
    setLoader(true);
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    let editidvalue = editid;
    console.log(editidvalue);
    if (
      bathroommodalname != null &&
      bathroommodalprice != null &&
      bathroommodalname != "" &&
      bathroommodalprice != "" &&
      bathroommodalname != undefined &&
      bathroommodalprice != undefined
    ) {
      const data = new FormData();
      data.append("name", bathroommodalname);
      data.append("price", bathroommodalprice);
      if (new2) {
        data.append("photo", bathroommodalimage);
      }

      console.log(bathroommodalisdefault);
      data.append("is_default", bathroommodalisdefault);
      fetch(url + "model-type/" + editidvalue, {
        method: "POST",
        body: data,
        headers: new Headers({
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoader(false);
          console.log(data);
          if (data.status) {
            setMessage(data.message);
            handleClick();
            handlebtnCloseedit();
            getBathroomModel();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      setMessage("Please fill all fields");
      handleClick();
    }
  }
  function DeleteBathroomModel() {
    setLoader(true);
    console.log("deletid" + deleteid);
    let da = deleteid;
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    // const data = new FormData();
    fetch(url + "model-type/" + da, {
      method: "DELETE",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoader(false);
        console.log(data);
        if (data.status) {
          handleClose("bathroomModelType");
          setMessage(data.message);
          handleClick();
          getBathroomModel();
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  }

  /*Bathroom shapes functions start here */

  function getBathroomShapes() {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "bathroom-shapes/get-all", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        if (data.data) {
          setBathroomShape(data.data);
        }
      })
      .catch(console.log);
  }

  function getBathroomShapessearch(data) {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "bathroom-shapes/get-all?search=" + data, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        if (data.data) {
          setBathroomShape(data.data);
        }
      })
      .catch(console.log);
  }

  function AddBathroomShapes() {
    setLoader(true);
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    if (
      bathroomShapeName != null &&
      bathroomShapePrice != null &&
      bathroomShapeName != "" &&
      bathroomShapePrice != "" &&
      bathroomShapeName != undefined &&
      bathroomShapePrice != undefined
    ) {
      const data = new FormData();
      data.append("name", bathroomShapeName);
      data.append("price", bathroomShapePrice);
      data.append("is_default", bathroomShapeIsDefault);
      if (
        bathroomShapeImage != null &&
        bathroomShapeImage != "" &&
        bathroomShapeImage != undefined
      ) {
        data.append("icon", bathroomShapeImage);
      }

      fetch(url + "bathroom-shapes/", {
        method: "POST",
        body: data,
        headers: new Headers({
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoader(false);
          console.log(data);
          if (data.status) {
            setMessage(data.message);
            handleClick();
            handlebtnClose();
            getBathroomShapes();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      setMessage("Please fill all fields");
      handleClick();
    }
  }

  function EditBathroomShapes() {
    setLoader(true);
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    let editidvalue = bathroomShapeEditid;
    console.log(editidvalue);
    if (
      bathroomShapeName != null &&
      bathroomShapePrice != null &&
      bathroomShapeName != "" &&
      bathroomShapePrice != "" &&
      bathroomShapeName != undefined &&
      bathroomShapePrice != undefined
    ) {
      const data = new FormData();
      data.append("name", bathroomShapeName);
      data.append("price", bathroomShapePrice);
      data.append("is_default", bathroomShapeIsDefault);
      if (new3) {
        data.append("icon", bathroomShapeImage);
      }
      fetch(url + "bathroom-shapes/" + editidvalue, {
        method: "POST",
        body: data,
        headers: new Headers({
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoader(false);
          console.log(data);
          if (data.status) {
            setMessage(data.message);
            handleClick();
            handlebtnCloseedit();
            getBathroomShapes();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      setMessage("Please fill all fields");
      handleClick();
    }
  }
  function DeleteBathroomshape() {
    setLoader(true);
    let da = bathroomShapeDeleteId;
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "bathroom-shapes/" + da, {
      method: "DELETE",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoader(false);
        console.log(data);
        if (data.status) {
          handleClose();
          setMessage(data.message);
          handleClick();
          getBathroomShapes();
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  }

  /*Bathroom shapes functions end here */

  /*Floor Plan functions start here */

  function getFloorPlan() {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "floor-plan/get-all", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("koo", data.data);
        if (data.data) {
          setFloorPlan(data.data);
        }
      })
      .catch(console.log);
  }

  function getFloorPlansearch(data) {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "floor-plan/get-all?search=" + data, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        if (data.data) {
          setFloorPlan(data.data);
        }
      })
      .catch(console.log);
  }

  function AddFloorPlan() {
    setLoader(true);
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    if (
      floorPlanName != null &&
      floorPlanPrice != null &&
      floorPlanName != "" &&
      floorPlanPrice != "" &&
      floorPlanName != undefined &&
      floorPlanPrice != undefined
    ) {
      const data = new FormData();
      data.append("name", floorPlanName);
      data.append("price", floorPlanPrice);
      data.append("is_default", floorPlanIsDefault);
      if (
        floorPlanImage != null &&
        floorPlanImage != "" &&
        floorPlanImage != undefined
      ) {
        data.append("photo", floorPlanImage);
      }

      fetch(url + "floor-plan/", {
        method: "POST",
        body: data,
        headers: new Headers({
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoader(false);
          console.log(data);
          if (data.status) {
            setMessage(data.message);
            handleClick();
            handlebtnClose();
            getFloorPlan();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      setMessage("Please fill all fields");
      handleClick();
    }
  }

  function EditFloorPlan() {
    setLoader(true);
    console.log("image", floorPlanImage);
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    let editidvalue = floorPlanEditid;
    console.log(editidvalue);
    if (
      floorPlanName != null &&
      floorPlanPrice != null &&
      floorPlanName != "" &&
      floorPlanPrice != "" &&
      floorPlanName != undefined &&
      floorPlanPrice != undefined
    ) {
      const data = new FormData();
      data.append("name", floorPlanName);
      data.append("price", floorPlanPrice);
      data.append("is_default", floorPlanIsDefault);
      if (new1) {
        data.append("photo", floorPlanImage);
      }
      fetch(url + "floor-plan/" + editidvalue, {
        method: "POST",
        body: data,
        headers: new Headers({
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoader(false);
          console.log(data);
          if (data.status) {
            setMessage(data.message);
            handleClick();
            handlebtnCloseedit();
            getFloorPlan();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      setMessage("Please fill all fields");
      handleClick();
    }
  }
  function DeleteFloorPlan() {
    setLoader(true);
    let da = floorPlanDeleteId;
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "floor-plan/" + da, {
      method: "DELETE",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoader(false);
        console.log(data);
        if (data.status) {
          handleClose();
          setMessage(data.message);
          handleClick();
          getFloorPlan();
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  }

  /*Floor Plan  functions end here */

  /*Floor Color functions start here */

  function getFloorColor() {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "floor-tile/get-all", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        if (data.data) {
          setFloorColor(data.data);
        }
      })
      .catch(console.log);
  }

  function getFloorColorsearch(data) {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "floor-tile/get-all?search=" + data, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        if (data.data) {
          setFloorColor(data.data);
        }
      })
      .catch(console.log);
  }

  function AddFloorColor() {
    setLoader(true);
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    if (
      floorColorName != null &&
      floorColorPrice != null &&
      floorColorCode != null &&
      floorColorName != "" &&
      floorColorPrice != "" &&
      floorColorCode != "" &&
      floorColorName != undefined &&
      floorColorPrice != undefined &&
      floorColorCode != undefined
    ) {
      const data = new FormData();
      data.append("name", floorColorName);
      data.append("price", floorColorPrice);
      data.append("code", floorColorCode);
      data.append("is_default", floorColorIsDefault);
      fetch(url + "floor-tile/", {
        method: "POST",
        body: data,
        headers: new Headers({
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoader(false);
          console.log(data);
          if (data.status) {
            setMessage(data.message);
            handleClick();
            handlebtnClose();
            getFloorColor();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      setMessage("Please fill all fields");
      handleClick();
    }
  }

  function EditFloorColor() {
    setLoader(true);
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    let editidvalue = floorColorEditid;
    console.log(editidvalue);
    if (
      floorColorName != null &&
      floorColorPrice != null &&
      floorColorCode != null &&
      floorColorName != "" &&
      floorColorPrice != "" &&
      floorColorCode != "" &&
      floorColorName != undefined &&
      floorColorPrice != undefined &&
      floorColorCode != undefined
    ) {
      const data = new FormData();
      data.append("name", floorColorName);
      data.append("price", floorColorPrice);
      data.append("code", floorColorCode);
      data.append("is_default", floorColorIsDefault);
      fetch(url + "floor-tile/" + editidvalue, {
        method: "POST",
        body: data,
        headers: new Headers({
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoader(false);
          console.log(data);
          if (data.status) {
            setMessage(data.message);
            handleClick();
            handlebtnCloseedit();
            getFloorColor();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      setMessage("Please fill all fields");
      handleClick();
    }
  }
  function DeleteFloorColor() {
    setLoader(true);
    let da = floorColorDeleteId;
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "floor-tile/" + da, {
      method: "DELETE",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoader(false);
        console.log(data);
        if (data.status) {
          handleClose();
          setMessage(data.message);
          handleClick();
          getFloorColor();
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  }

  /*Floor Color  functions end here */
  /*Accent Color functions start here */

  function getAccentColor() {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "accent-tile/get-all", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        if (data.data) {
          setAccentColor(data.data);
        }
      })
      .catch(console.log);
  }

  function getAccentColorsearch(data) {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "accent-tile/get-all?search=" + data, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        if (data.data) {
          setAccentColor(data.data);
        }
      })
      .catch(console.log);
  }

  function AddAccentColor() {
    setLoader(true);
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    if (
      accentColorName != null &&
      accentColorPrice != null &&
      accentColorCode != null &&
      accentColorName != "" &&
      accentColorPrice != "" &&
      accentColorCode != "" &&
      accentColorName != undefined &&
      accentColorPrice != undefined &&
      accentColorCode != undefined
    ) {
      const data = new FormData();
      data.append("name", accentColorName);
      data.append("price", accentColorPrice);
      data.append("code", accentColorCode);
      data.append("is_default", accentColorIsDefault);
      fetch(url + "accent-tile/", {
        method: "POST",
        body: data,
        headers: new Headers({
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoader(false);
          console.log(data);
          if (data.status) {
            setMessage(data.message);
            handleClick();
            handlebtnClose();
            getAccentColor();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      setMessage("Please fill all fields");
      handleClick();
    }
  }

  function EditAccentColor() {
    setLoader(true);
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    let editidvalue = accentColorEditid;
    console.log(editidvalue);
    if (
      accentColorName != null &&
      accentColorPrice != null &&
      accentColorCode != null &&
      accentColorName != "" &&
      accentColorPrice != "" &&
      accentColorCode != "" &&
      accentColorName != undefined &&
      accentColorPrice != undefined &&
      accentColorCode != undefined
    ) {
      const data = new FormData();
      data.append("name", accentColorName);
      data.append("price", accentColorPrice);
      data.append("code", accentColorCode);
      data.append("is_default", accentColorIsDefault);
      fetch(url + "accent-tile/" + editidvalue, {
        method: "POST",
        body: data,
        headers: new Headers({
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoader(false);
          console.log(data);
          if (data.status) {
            setMessage(data.message);
            handleClick();
            handlebtnCloseedit();
            getAccentColor();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      setMessage("Please fill all fields");
      handleClick();
    }
  }
  function DeleteAccentColor() {
    setLoader(true);
    let da = accentColorDeleteId;
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "accent-tile/" + da, {
      method: "DELETE",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoader(false);
        console.log(data);
        if (data.status) {
          handleClose();
          setMessage(data.message);
          handleClick();
          getAccentColor();
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  }

  /*Accent Color  functions end here */

  /*Vanity Color/ functions start here */
  function getVanityColor() {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "vanity-color/get-all", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        if (data.data) {
          setVanityColor(data.data);
        }
      })
      .catch(console.log);
  }

  function getVanityColorsearch(data) {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "vanity-color/get-all?search=" + data, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        if (data.data) {
          setVanityColor(data.data);
        }
      })
      .catch(console.log);
  }

  function AddVanityColor() {
    setLoader(true);
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    if (
      vanityColorName != null &&
      vanityColorPrice != null &&
      vanityColorCode != null &&
      vanityColorName != "" &&
      vanityColorPrice != "" &&
      vanityColorCode != "" &&
      vanityColorName != undefined &&
      vanityColorPrice != undefined &&
      vanityColorCode != undefined
    ) {
      const data = new FormData();
      data.append("name", vanityColorName);
      data.append("price", vanityColorPrice);
      data.append("code", vanityColorCode);
      data.append("is_default", vanityColorIsDefault);
      fetch(url + "vanity-color/", {
        method: "POST",
        body: data,
        headers: new Headers({
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoader(false);
          console.log(data);
          if (data.status) {
            setMessage(data.message);
            handleClick();
            handlebtnClose();
            getVanityColor();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      setMessage("Please fill all fields");
      handleClick();
    }
  }

  function EditVanityColor() {
    setLoader(true);
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    let editidvalue = vanityColorEditid;
    console.log(editidvalue);
    if (
      vanityColorName != null &&
      vanityColorPrice != null &&
      vanityColorCode != null &&
      vanityColorName != "" &&
      vanityColorPrice != "" &&
      vanityColorCode != "" &&
      vanityColorName != undefined &&
      vanityColorPrice != undefined &&
      vanityColorCode != undefined
    ) {
      const data = new FormData();
      data.append("name", vanityColorName);
      data.append("price", vanityColorPrice);
      data.append("code", vanityColorCode);
      data.append("is_default", vanityColorIsDefault);
      fetch(url + "vanity-color/" + editidvalue, {
        method: "POST",
        body: data,
        headers: new Headers({
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoader(false);
          console.log(data);
          if (data.status) {
            setMessage(data.message);
            handleClick();
            handlebtnCloseedit();
            getVanityColor();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      setMessage("Please fill all fields");
      handleClick();
    }
  }
  function DeleteVanityColor() {
    setLoader(true);
    let da = vanityColorDeleteId;
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "vanity-color/" + da, {
      method: "DELETE",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoader(false);
        console.log(data);
        if (data.status) {
          handleClose();
          setMessage(data.message);
          handleClick();
          getAccentColor();
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  }

  /*Vanity Color  functions end here */

  /*Wall Color functions start here */
  function getWallColor() {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "wall-tile/get-all", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        if (data.data) {
          setWallColor(data.data);
        }
      })
      .catch(console.log);
  }

  function getWallColorsearch(data) {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "wall-tile/get-all?search=" + data, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        if (data.data) {
          setWallColor(data.data);
        }
      })
      .catch(console.log);
  }

  function AddWallColor() {
    setLoader(true);
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    if (
      wallColorName != null &&
      wallColorPrice != null &&
      wallColorCode != null &&
      wallColorName != "" &&
      wallColorPrice != "" &&
      wallColorCode != "" &&
      wallColorName != undefined &&
      wallColorPrice != undefined &&
      wallColorCode != undefined
    ) {
      const data = new FormData();
      data.append("name", wallColorName);
      data.append("price", wallColorPrice);
      data.append("code", wallColorCode);
      data.append("is_default", wallColorIsDefault);
      fetch(url + "wall-tile/", {
        method: "POST",
        body: data,
        headers: new Headers({
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoader(false);

          console.log(data);
          if (data.status) {
            setMessage(data.message);
            handleClick();
            handlebtnClose();
            getWallColor();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      setMessage("Please fill all fields");
      handleClick();
    }
  }

  function EditWallColor() {
    setLoader(true);
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    let editidvalue = wallColorEditid;
    console.log(editidvalue);
    if (
      wallColorName != null &&
      wallColorPrice != null &&
      wallColorCode != null &&
      wallColorName != "" &&
      wallColorPrice != "" &&
      wallColorCode != "" &&
      wallColorName != undefined &&
      wallColorPrice != undefined &&
      wallColorCode != undefined
    ) {
      const data = new FormData();
      data.append("name", wallColorName);
      data.append("price", wallColorPrice);
      data.append("code", wallColorCode);
      data.append("is_default", wallColorIsDefault);
      fetch(url + "wall-tile/" + editidvalue, {
        method: "POST",
        body: data,
        headers: new Headers({
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoader(false);
          console.log(data);
          if (data.status) {
            setMessage(data.message);
            handleClick();
            handlebtnCloseedit();
            getWallColor();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      setMessage("Please fill all fields");
      handleClick();
    }
  }
  function DeleteWallColor() {
    setLoader(true);

    let da = wallColorDeleteId;
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch(url + "wall-tile/" + da, {
      method: "DELETE",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoader(false);

        console.log(data);
        if (data.status) {
          handleClose();
          setMessage(data.message);
          handleClick();
          getWallColor();
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  }

  const searchfun = (data, type) => {
    console.log(data);
    console.log(type);
    if (type == "1") {
      if (data == "" || data.length < 1) {
        getBathroomModel();
      }
      if (data.length > 3) {
        console.log(data);
        getBathroomModelsearch(data);
      }
    }
    if (type == "2") {
      if (data == "" || data.length < 1) {
        getBathroomShapes();
      }
      if (data.length > 3) {
        console.log(data);
        getBathroomShapessearch(data);
      }
    }
    if (type == "3") {
      if (data == "" || data.length < 1) {
        getFloorPlan();
      }
      if (data.length > 3) {
        console.log(data);
        getFloorPlansearch(data);
      }
    }
    if (type == "4") {
      if (data == "" || data.length < 1) {
        getFloorColor();
      }
      if (data.length > 3) {
        console.log(data);
        getFloorColorsearch(data);
      }
    }
    if (type == "5") {
      if (data == "" || data.length < 1) {
        getVanityColor();
      }
      if (data.length > 3) {
        console.log(data);
        getVanityColorsearch(data);
      }
    }
    if (type == "6") {
      if (data == "" || data.length < 1) {
        getWallColor();
      }
      if (data.length > 3) {
        console.log(data);
        getWallColorsearch(data);
      }
    }
    if (type == "7") {
      if (data == "" || data.length < 1) {
        getAccentColor();
      }
      if (data.length > 3) {
        console.log(data);
        getAccentColorsearch(data);
      }
    }
  };

  /*Wall Color  functions end here */
  /** accordian */

  const handleAccordionChange = (panel) => (e, isExpanded) => {
    console.log({ e, isExpanded });
    console.log({ panel });
    setExpandedPanel(isExpanded ? panel : false);
  };

  /**accordian end here */
  return (
    <div>
      <h3 className={classes.headertitle}>
        Welcome back {userinfo.user?.name}!
      </h3>
      <p className={classes.notificationtitle}>
        <img className={classes.notiicon} src={notiicon} />
        You have 2 appointments this week.
      </p>

      <GridContainer>
        <div className={classes.reporttag}>Modal Configurator Setting</div>

        {/* Bathroom Model type start here*/}
        <div
          style={{
            width: "100%",
            display: "flex",
            padding: "2%",
            overflowX: "auto",
            flexDirection: "column",
            backgroundColor: "#eee",
            MsOverflowStyle: "-ms-autohiding-scrollbar",
            WebkitOverflowScrolling: "touch",
          }}
        >
          <Accordion
            expanded={expandedPanel === "panel1"}
            onChange={handleAccordionChange("panel1")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <b>Bathroom Model</b>
            </AccordionSummary>

            <AccordionDetails>
              <div className={classes.tableUpgradeWrapper}>
                <div className={classes.filterbtnbox}>
                  <button
                    onClick={handleClickbtnOpen}
                    className={classes.addbtn}
                  >
                    Add
                  </button>
                  <input
                    type="text"
                    placeholder="Search..."
                    className={classes.searchbox}
                    onChange={(e) => searchfun(e.target.value, "1")}
                  />
                </div>
                <div style={{ width: "100%", overflowX: "auto" }}>
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th className={classes.colortd}>Name</th>
                        <th className={classes.colortd}>Image</th>
                        <th className={classes.colortd}>Price</th>
                        <th className={classes.colortd}>IsDefault</th>
                        <th className={classes.colortd}>Action(s)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {BathroomModel?.map((data, key) => {
                        return (
                          <tr key={key}>
                            <td className={classes.center}>{data.name}</td>
                            <td className={classes.center}>
                              <img
                                className={classes.imagephoto}
                                src={data.photo}
                              />
                            </td>
                            <td className={classes.center}>{data.price}</td>
                            <td className={classes.center}>
                              {data.is_default}
                            </td>
                            <td className={classes.center}>
                              <img
                                onClick={() =>
                                  handleClickOpen(data.id, "bathroomModelType")
                                }
                                className={classes.dropdownimg}
                                src={deleteimg}
                              />
                              <img
                                onClick={() =>
                                  handleClickOpenedit(data, "bathroomModelType")
                                }
                                className={classes.editimage}
                                src={edit}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expandedPanel === "panel2"}
            onChange={handleAccordionChange("panel2")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <b>Bathroom shapes</b>
            </AccordionSummary>

            <AccordionDetails>
              <div className={classes.tableUpgradeWrapper}>
                <div className={classes.filterbtnbox}>
                  <button
                    onClick={handleClickbtnOpenBathroomShape}
                    className={classes.addbtn}
                  >
                    Add
                  </button>
                  <input
                    type="text"
                    placeholder="Search..."
                    className={classes.searchbox}
                    onChange={(e) => searchfun(e.target.value, "2")}
                  />
                </div>
                <div style={{ width: "100%", overflowX: "auto" }}>
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th className={classes.colortd}>Name</th>
                        <th className={classes.colortd}>Image</th>
                        <th className={classes.colortd}>Price</th>
                        <th className={classes.colortd}>IsDefault</th>
                        <th className={classes.colortd}>Action(s)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bathroomShape?.map((data, key) => {
                        return (
                          <tr key={key}>
                            <td className={classes.center}>{data.name}</td>
                            <td className={classes.center}>
                              <img
                                className={classes.imagephoto}
                                src={data.icon}
                              />
                            </td>
                            <td className={classes.center}>{data.price}</td>
                            <td className={classes.center}>
                              {data.is_default}
                            </td>
                            <td className={classes.center}>
                              <img
                                onClick={() =>
                                  handleClickOpen(data.id, "bathroomShape")
                                }
                                className={classes.dropdownimg}
                                src={deleteimg}
                              />
                              <img
                                onClick={() =>
                                  handleClickOpenedit(data, "bathroomShape")
                                }
                                className={classes.editimage}
                                src={edit}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Bathroom shapes end here*/}
            </AccordionDetails>
          </Accordion>

          {/**floor Plan code start here*/}
          <Accordion
            expanded={expandedPanel === "panel3"}
            onChange={handleAccordionChange("panel3")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <b>Floor Plan</b>
            </AccordionSummary>

            <AccordionDetails>
              <div className={classes.tableUpgradeWrapper}>
                <div className={classes.filterbtnbox}>
                  <button
                    onClick={handleClickbtnOpenFloorPlan}
                    className={classes.addbtn}
                  >
                    Add
                  </button>
                  <input
                    type="text"
                    placeholder="Search..."
                    className={classes.searchbox}
                    onChange={(e) => searchfun(e.target.value, "3")}
                  />
                </div>
                <div style={{ width: "100%", overflowX: "auto" }}>
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th className={classes.colortd}>Name</th>
                        <th className={classes.colortd}>Image</th>
                        <th className={classes.colortd}>Price</th>
                        <th className={classes.colortd}>IsDefault</th>
                        <th className={classes.colortd}>Action(s)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {floorPlan?.map((data, key) => {
                        return (
                          <tr key={key}>
                            <td className={classes.center}>{data.name}</td>
                            <td className={classes.center}>
                              <img
                                className={classes.imagephoto}
                                src={data.photo}
                              />
                            </td>
                            <td className={classes.center}>{data.price}</td>
                            <td className={classes.center}>
                              {data.is_default}
                            </td>
                            <td className={classes.center}>
                              <img
                                onClick={() =>
                                  handleClickOpen(data.id, "floorPlan")
                                }
                                className={classes.dropdownimg}
                                src={deleteimg}
                              />
                              <img
                                onClick={() =>
                                  handleClickOpenedit(data, "floorPlan")
                                }
                                className={classes.editimage}
                                src={edit}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* Floor Plan start here*/}
            </AccordionDetails>
          </Accordion>
          {/* Floor Plan end here*/}

          {/**floor Color code start here*/}
          <Accordion
            expanded={expandedPanel === "panel4"}
            onChange={handleAccordionChange("panel4")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <b>Folor Tiles Color</b>
            </AccordionSummary>

            <AccordionDetails>
              <div className={classes.tableUpgradeWrapper}>
                <div className={classes.filterbtnbox}>
                  <button
                    onClick={handleClickbtnOpenFloorColor}
                    className={classes.addbtn}
                  >
                    Add
                  </button>
                  <input
                    type="text"
                    placeholder="Search..."
                    className={classes.searchbox}
                    onChange={(e) => searchfun(e.target.value, "4")}
                  />
                </div>
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th className={classes.colortd}>Name</th>
                        <th className={classes.colortd}>Code</th>
                        <th className={classes.colortd}>Price</th>
                        <th className={classes.colortd}>IsDefault</th>
                        <th className={classes.colortd}>Action(s)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {floorColor?.map((data, key) => {
                        return (
                          <tr key={key}>
                            <td className={classes.center}>{data.name}</td>
                            <td className={classes.center}>{data.code}</td>
                            <td className={classes.center}>{data.price}</td>
                            <td className={classes.center}>
                              {data.is_default}
                            </td>
                            <td className={classes.center}>
                              <img
                                onClick={() =>
                                  handleClickOpen(data.id, "floorColor")
                                }
                                className={classes.dropdownimg}
                                src={deleteimg}
                              />
                              <img
                                onClick={() =>
                                  handleClickOpenedit(data, "floorColor")
                                }
                                className={classes.editimage}
                                src={edit}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          {/* Floor Color end here*/}

          {/**Vanity Color code start here*/}
          <Accordion
            expanded={expandedPanel === "panel5"}
            onChange={handleAccordionChange("panel5")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <b>Vanity Tiles Color</b>
            </AccordionSummary>

            <AccordionDetails>
              <div className={classes.tableUpgradeWrapper}>
                <div className={classes.filterbtnbox}>
                  <button
                    onClick={handleClickbtnOpenVanityColor}
                    className={classes.addbtn}
                  >
                    Add
                  </button>
                  <input
                    type="text"
                    placeholder="Search..."
                    className={classes.searchbox}
                    onChange={(e) => searchfun(e.target.value, "5")}
                  />
                </div>
                <div style={{ width: "100%", overflowX: "auto" }}>
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th className={classes.colortd}>Name</th>
                        <th className={classes.colortd}>Code</th>
                        <th className={classes.colortd}>Price</th>
                        <th className={classes.colortd}>IsDefault</th>
                        <th className={classes.colortd}>Action(s)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vanityColor?.map((data, key) => {
                        return (
                          <tr key={key}>
                            <td className={classes.center}>{data.name}</td>
                            <td className={classes.center}>{data.code}</td>
                            <td className={classes.center}>{data.price}</td>
                            <td className={classes.center}>
                              {data.is_default}
                            </td>
                            <td className={classes.center}>
                              <img
                                onClick={() =>
                                  handleClickOpen(data.id, "vanityColor")
                                }
                                className={classes.dropdownimg}
                                src={deleteimg}
                              />
                              <img
                                onClick={() =>
                                  handleClickOpenedit(data, "vanityColor")
                                }
                                className={classes.editimage}
                                src={edit}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          {/* Vanity Color end here*/}

          {/**Accent Color code start here*/}
          <Accordion
            expanded={expandedPanel === "panel6"}
            onChange={handleAccordionChange("panel6")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <b>Accent Tiles Color</b>
            </AccordionSummary>

            <AccordionDetails>
              <div className={classes.tableUpgradeWrapper}>
                <div className={classes.filterbtnbox}>
                  <button
                    onClick={handleClickbtnOpenAccentColor}
                    className={classes.addbtn}
                  >
                    Add
                  </button>
                  <input
                    type="text"
                    placeholder="Search..."
                    className={classes.searchbox}
                    onChange={(e) => searchfun(e.target.value, "7")}
                  />
                </div>
                <div style={{ width: "100%", overflowX: "auto" }}>
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th className={classes.colortd}>Name</th>
                        <th className={classes.colortd}>Code</th>
                        <th className={classes.colortd}>Price</th>
                        <th className={classes.colortd}>IsDefault</th>
                        <th className={classes.colortd}>Action(s)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {accentColor?.map((data, key) => {
                        return (
                          <tr key={key}>
                            <td className={classes.center}>{data.name}</td>
                            <td className={classes.center}>{data.code}</td>
                            <td className={classes.center}>{data.price}</td>
                            <td className={classes.center}>
                              {data.is_default}
                            </td>
                            <td className={classes.center}>
                              <img
                                onClick={() =>
                                  handleClickOpen(data.id, "accentColor")
                                }
                                className={classes.dropdownimg}
                                src={deleteimg}
                              />
                              <img
                                onClick={() =>
                                  handleClickOpenedit(data, "accentColor")
                                }
                                className={classes.editimage}
                                src={edit}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          {/* Accent Color end here*/}

          {/**Wall tiles Color code start here*/}
          <Accordion
            expanded={expandedPanel === "panel7"}
            onChange={handleAccordionChange("panel7")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <b>Wall Tiles Color</b>
            </AccordionSummary>

            <AccordionDetails>
              <div className={classes.tableUpgradeWrapper}>
                <div className={classes.filterbtnbox}>
                  <button
                    onClick={handleClickbtnOpenWallColor}
                    className={classes.addbtn}
                  >
                    Add
                  </button>
                  <input
                    type="text"
                    placeholder="Search..."
                    className={classes.searchbox}
                    onChange={(e) => searchfun(e.target.value, "6")}
                  />
                </div>
                <div style={{ width: "100%", overflowX: "auto" }}>
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th className={classes.colortd}>Name</th>
                        <th className={classes.colortd}>Code</th>
                        <th className={classes.colortd}>Price</th>
                        <th className={classes.colortd}>IsDefault</th>
                        <th className={classes.colortd}>Action(s)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wallColor?.map((data, key) => {
                        return (
                          <tr key={key}>
                            <td className={classes.center}>{data.name}</td>
                            <td className={classes.center}>{data.code}</td>
                            <td className={classes.center}>{data.price}</td>
                            <td className={classes.center}>
                              {data.is_default}
                            </td>
                            <td className={classes.center}>
                              <img
                                onClick={() =>
                                  handleClickOpen(data.id, "wallColor")
                                }
                                className={classes.dropdownimg}
                                src={deleteimg}
                              />
                              <img
                                onClick={() =>
                                  handleClickOpenedit(data, "wallColor")
                                }
                                className={classes.editimage}
                                src={edit}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          {/* Wall tile Color end here*/}
        </div>
        <Snackbar
          open={opentoast}
          autoHideDuration={900}
          onClose={handletoastClose}
          message={message}
        />
      </GridContainer>
      <div>
        <div className={classes.textaligncenter}>
          <Dialog
            className={classes.textaligncenter}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
            <DialogContent>
              <DialogContentText
                className={classes.textaligncenter}
                id="alert-dialog-description"
              >
                Do you really want to delete this record? This process cannot be
                undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.textaligncenter}>
              {!loader ? (
                <div className={classes.textalignbtn}>
                  <button onClick={handleClose} className={classes.cancelbtn}>
                    Cancel
                  </button>
                  <button
                    onClick={DeleteBathroomModel}
                    className={classes.deletebtn}
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <p>...Loading</p>
              )}
            </DialogActions>
          </Dialog>
        </div>
        <div id="popup-register" className={classes.dialogtextaligncenter}>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            className={classes.textaligncenter}
            open={openbtn}
            onClose={handlebtnClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Add Bathroom Model"}
            </DialogTitle>
            <DialogActions className={classes.textaligncenter}>
              <div className={classes.textalignbtn}>
                <input
                  value={bathroommodalname}
                  onChange={(e) => setbathroommodalname(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Name"
                ></input>
                <input
                  value={bathroommodalprice}
                  onChange={(e) => setbathroommodalprice(e.target.value)}
                  className={classes.registertextfield}
                  type="number"
                  placeholder="Enter Price"
                ></input>
                <div className={classes.uploadImgWrapper}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={handleBathroomModelImage}
                    />
                    <img className={classes.cameraimage} src={cameraimg} />
                  </IconButton>
                  <FormGroup className={classes.displycss}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checked}
                          onChange={handleswitch}
                          name="gilad"
                        />
                      }
                      label="IsDefault"
                    />
                  </FormGroup>
                </div>
                {!loader ? (
                  <>
                    <button
                      onClick={handlebtnClose}
                      className={classes.cancelbtn}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={AddBathroomModel}
                      className={classes.sendbtn}
                    >
                      Add Model
                    </button>
                  </>
                ) : (
                  <p>...Loading</p>
                )}
              </div>
            </DialogActions>
          </Dialog>
        </div>
        <div id="popup-register" className={classes.dialogtextaligncenter}>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            className={classes.textaligncenter}
            open={openbtnedit}
            onClose={handlebtnCloseedit}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Update An Bathroom Model"}
            </DialogTitle>
            <DialogActions className={classes.textaligncenter}>
              <div className={classes.textalignbtn}>
                <input
                  value={bathroommodalname}
                  onChange={(e) => setbathroommodalname(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Name"
                ></input>
                <input
                  value={bathroommodalprice}
                  onChange={(e) => setbathroommodalprice(e.target.value)}
                  className={classes.registertextfield}
                  type="number"
                  placeholder="Enter Price"
                ></input>
                <div className={classes.uploadImgWrapper}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={handleBathroomModelImage}
                    />
                    <img className={classes.cameraimage} src={cameraimg} />
                  </IconButton>
                  <FormGroup className={classes.displycss}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checked}
                          onChange={handleswitch}
                          name="gilad"
                        />
                      }
                      label="IsDefault"
                    />
                  </FormGroup>
                </div>
                {!loader ? (
                  <>
                    <button
                      onClick={handlebtnCloseedit}
                      className={classes.cancelbtn}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={EditBathroomModel}
                      className={classes.sendbtn}
                    >
                      Update
                    </button>
                  </>
                ) : (
                  <p>...Loading</p>
                )}
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </div>

      <div>
        <div className={classes.textaligncenter}>
          <Dialog
            className={classes.textaligncenter}
            open={bathroomShapeOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
            <DialogContent>
              <DialogContentText
                className={classes.textaligncenter}
                id="alert-dialog-description"
              >
                Do you really want to delete this record? This process cannot be
                undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.textaligncenter}>
              {!loader ? (
                <div className={classes.textalignbtn}>
                  <button onClick={handleClose} className={classes.cancelbtn}>
                    Cancel
                  </button>
                  <button
                    onClick={DeleteBathroomshape}
                    className={classes.deletebtn}
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <p>...Loading</p>
              )}
            </DialogActions>
          </Dialog>
        </div>
        <div id="popup-register" className={classes.dialogtextaligncenter}>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            className={classes.textaligncenter}
            open={bathroomShapeOpenBtn}
            onClose={handlebtnClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {" Add Bathroom Model Shape "}
            </DialogTitle>

            <DialogActions className={classes.textaligncenter}>
              <div className={classes.textalignbtn}>
                <input
                  value={bathroomShapeName}
                  onChange={(e) => setBathroomShapeName(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Name"
                ></input>
                <input
                  value={bathroomShapePrice}
                  onChange={(e) => setBathroomShapePrice(e.target.value)}
                  className={classes.registertextfield}
                  type="number"
                  placeholder="Enter Price"
                ></input>
                <div className={classes.uploadImgWrapper}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={handleBathroomShapeImage}
                    />
                    <img className={classes.cameraimage} src={cameraimg} />
                  </IconButton>
                  <FormGroup className={classes.displycss}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={bathroomShapeChecked}
                          onChange={handleswitchBathroomShapes}
                          name="gilad"
                        />
                      }
                      label="IsDefault"
                    />
                  </FormGroup>
                </div>
                {!loader ? (
                  <>
                    <button
                      onClick={handlebtnClose}
                      className={classes.cancelbtn}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={AddBathroomShapes}
                      className={classes.sendbtn}
                    >
                      Add Shape
                    </button>
                  </>
                ) : (
                  <p>...Loading</p>
                )}
              </div>
            </DialogActions>
          </Dialog>
        </div>
        {/*Edit popup*/}
        <div id="popup-register" className={classes.dialogtextaligncenter}>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            className={classes.textaligncenter}
            open={bathroomShapeOpenBtnEdit}
            onClose={handlebtnCloseedit}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Edit Bathroom Shape Details"}
            </DialogTitle>
            <DialogActions className={classes.textaligncenter}>
              <div className={classes.textalignbtn}>
                <input
                  value={bathroomShapeName}
                  onChange={(e) => setBathroomShapeName(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Name"
                ></input>
                <input
                  value={bathroomShapePrice}
                  onChange={(e) => setBathroomShapePrice(e.target.value)}
                  className={classes.registertextfield}
                  type="number"
                  placeholder="Enter Price"
                ></input>
                <div className={classes.uploadImgWrapper}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={handleBathroomShapeImage}
                    />
                    <img className={classes.cameraimage} src={cameraimg} />
                  </IconButton>
                  <FormGroup className={classes.displycss}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={bathroomShapeChecked}
                          onChange={handleswitchBathroomShapes}
                          name="gilad"
                        />
                      }
                      label="IsDefault"
                    />
                  </FormGroup>
                </div>

                {!loader ? (
                  <>
                    <button
                      onClick={handlebtnCloseedit}
                      className={classes.cancelbtn}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={EditBathroomShapes}
                      className={classes.sendbtn}
                    >
                      Update
                    </button>
                  </>
                ) : (
                  <p>...Loading</p>
                )}
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <div>
        <div className={classes.textaligncenter}>
          <Dialog
            className={classes.textaligncenter}
            open={floorPlanOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
            <DialogContent>
              <DialogContentText
                className={classes.textaligncenter}
                id="alert-dialog-description"
              >
                Do you really want to delete this record? This process cannot be
                undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.textaligncenter}>
              {!loader ? (
                <div className={classes.textalignbtn}>
                  <button onClick={handleClose} className={classes.cancelbtn}>
                    Cancel
                  </button>
                  <button
                    onClick={DeleteFloorPlan}
                    className={classes.deletebtn}
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <p>...Loading</p>
              )}
            </DialogActions>
          </Dialog>
        </div>
        <div id="popup-register" className={classes.dialogtextaligncenter}>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            className={classes.textaligncenter}
            open={floorPlanOpenBtn}
            onClose={handlebtnClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {" Add Floor Plan "}
            </DialogTitle>

            <DialogActions className={classes.textaligncenter}>
              <div className={classes.textalignbtn}>
                <input
                  value={floorPlanName}
                  onChange={(e) => setFloorPlanName(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Name"
                ></input>
                <input
                  value={floorPlanPrice}
                  onChange={(e) => setFloorPlanPrice(e.target.value)}
                  className={classes.registertextfield}
                  type="number"
                  placeholder="Enter Price"
                ></input>
                <div className={classes.uploadImgWrapper}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={handleImageFloorPlan}
                    />
                    <img className={classes.cameraimage} src={cameraimg} />
                  </IconButton>
                  <FormGroup className={classes.displycss}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={floorPlanChecked}
                          onChange={handleswitchFloorPlan}
                          name="gilad"
                        />
                      }
                      label="IsDefault"
                    />
                  </FormGroup>
                </div>

                {!loader ? (
                  <>
                    <button
                      onClick={handlebtnClose}
                      className={classes.cancelbtn}
                    >
                      Cancel
                    </button>
                    <button onClick={AddFloorPlan} className={classes.sendbtn}>
                      Add
                    </button>
                  </>
                ) : (
                  <p>...Loading</p>
                )}
              </div>
            </DialogActions>
          </Dialog>
        </div>
        {/*Edit popup*/}
        <div id="popup-register" className={classes.dialogtextaligncenter}>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            className={classes.textaligncenter}
            open={floorPlanOpenBtnEdit}
            onClose={handlebtnCloseedit}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Edit Floor Plan Details"}
            </DialogTitle>
            <DialogActions className={classes.textaligncenter}>
              <div className={classes.textalignbtn}>
                <input
                  value={floorPlanName}
                  onChange={(e) => setFloorPlanName(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Name"
                ></input>
                <input
                  value={floorPlanPrice}
                  onChange={(e) => setFloorPlanPrice(e.target.value)}
                  className={classes.registertextfield}
                  type="number"
                  placeholder="Enter Price"
                ></input>
                <div className={classes.uploadImgWrapper}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={handleImageFloorPlan}
                    />
                    <img className={classes.cameraimage} src={cameraimg} />
                  </IconButton>
                  <FormGroup className={classes.displycss}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={floorPlanChecked}
                          onChange={handleswitchFloorPlan}
                          name="gilad"
                        />
                      }
                      label="IsDefault"
                    />
                  </FormGroup>
                </div>
                {!loader ? (
                  <>
                    <button
                      onClick={handlebtnCloseedit}
                      className={classes.cancelbtn}
                    >
                      Cancel
                    </button>
                    <button onClick={EditFloorPlan} className={classes.sendbtn}>
                      Update
                    </button>
                  </>
                ) : (
                  <p>...Loading</p>
                )}
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </div>

      <div>
        <div className={classes.textaligncenter}>
          <Dialog
            className={classes.textaligncenter}
            open={floorColorOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
            <DialogContent>
              <DialogContentText
                className={classes.textaligncenter}
                id="alert-dialog-description"
              >
                Do you really want to delete this record? This process cannot be
                undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.textaligncenter}>
              {!loader ? (
                <div className={classes.textalignbtn}>
                  <button onClick={handleClose} className={classes.cancelbtn}>
                    Cancel
                  </button>
                  <button
                    onClick={DeleteFloorColor}
                    className={classes.deletebtn}
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <p>...Loading</p>
              )}
            </DialogActions>
          </Dialog>
        </div>
        <div id="popup-register" className={classes.dialogtextaligncenter}>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            className={classes.textaligncenter}
            open={floorColorOpenBtn}
            onClose={handlebtnClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {" Add Floor Color Details "}
            </DialogTitle>

            <DialogActions className={classes.textaligncenter}>
              <div className={classes.textalignbtn}>
                <input
                  value={floorColorName}
                  onChange={(e) => setFloorColorName(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Color Name"
                ></input>
                <input
                  value={floorColorCode}
                  onChange={(e) => setFloorColorCode(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Color Code"
                ></input>
                <input
                  value={floorColorPrice}
                  onChange={(e) => setFloorColorPrice(e.target.value)}
                  className={classes.registertextfield}
                  type="number"
                  placeholder="Enter Price"
                ></input>
                <div>
                  <FormGroup className={classes.displycss}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={floorColorChecked}
                          onChange={handleswitchFloorColor}
                          name="gilad"
                        />
                      }
                      label="IsDefault"
                    />
                  </FormGroup>
                </div>
                {!loader ? (
                  <>
                    <button
                      onClick={handlebtnClose}
                      className={classes.cancelbtn}
                    >
                      Cancel
                    </button>
                    <button onClick={AddFloorColor} className={classes.sendbtn}>
                      Add
                    </button>
                  </>
                ) : (
                  <p>...Loading</p>
                )}
              </div>
            </DialogActions>
          </Dialog>
        </div>
        {/*Edit popup*/}
        <div id="popup-register" className={classes.dialogtextaligncenter}>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            className={classes.textaligncenter}
            open={floorColorOpenBtnEdit}
            onClose={handlebtnCloseedit}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Edit Floor Color Details"}
            </DialogTitle>
            <DialogActions className={classes.textaligncenter}>
              <div className={classes.textalignbtn}>
                <input
                  value={floorColorName}
                  onChange={(e) => setFloorColorName(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Color Name"
                ></input>
                <input
                  value={floorColorCode}
                  onChange={(e) => setFloorColorCode(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Color Code"
                ></input>
                <input
                  value={floorColorPrice}
                  onChange={(e) => setFloorColorPrice(e.target.value)}
                  className={classes.registertextfield}
                  type="number"
                  placeholder="Enter Color Price"
                ></input>
                <div className={classes.uploadImgWrapper}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input hidden accept="image/*" type="file" />
                    <img className={classes.cameraimage} src={cameraimg} />
                  </IconButton>
                  <FormGroup className={classes.displycss}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={floorColorChecked}
                          onChange={handleswitchFloorColor}
                          name="gilad"
                        />
                      }
                      label="IsDefault"
                    />
                  </FormGroup>
                </div>
                {!loader ? (
                  <>
                    <button
                      onClick={handlebtnCloseedit}
                      className={classes.cancelbtn}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={EditFloorColor}
                      className={classes.sendbtn}
                    >
                      Update
                    </button>
                  </>
                ) : (
                  <p>...Loading</p>
                )}
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </div>

      <div>
        <div className={classes.textaligncenter}>
          <Dialog
            className={classes.textaligncenter}
            open={vanityColorOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
            <DialogContent>
              <DialogContentText
                className={classes.textaligncenter}
                id="alert-dialog-description"
              >
                Do you really want to delete this record? This process cannot be
                undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.textaligncenter}>
              {!loader ? (
                <div className={classes.textalignbtn}>
                  <button onClick={handleClose} className={classes.cancelbtn}>
                    Cancel
                  </button>
                  <button
                    onClick={DeleteVanityColor}
                    className={classes.deletebtn}
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <p>...Loading</p>
              )}
            </DialogActions>
          </Dialog>
        </div>
        <div id="popup-register" className={classes.dialogtextaligncenter}>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            className={classes.textaligncenter}
            open={vanityColorOpenBtn}
            onClose={handlebtnClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {" Add Vanity Tile Color Details "}
            </DialogTitle>

            <DialogActions className={classes.textaligncenter}>
              <div className={classes.textalignbtn}>
                <input
                  value={vanityColorName}
                  onChange={(e) => setVanityColorName(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Color Name"
                ></input>
                <input
                  value={vanityColorCode}
                  onChange={(e) => setVanityColorCode(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Color Code"
                ></input>
                <input
                  value={vanityColorPrice}
                  onChange={(e) => setVanityColorPrice(e.target.value)}
                  className={classes.registertextfield}
                  type="number"
                  placeholder="Enter Price"
                ></input>
                <div>
                  <FormGroup className={classes.displycss}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={vanityColorChecked}
                          onChange={handleswitchVanityColor}
                          name="gilad"
                        />
                      }
                      label="IsDefault"
                    />
                  </FormGroup>
                </div>
                {!loader ? (
                  <>
                    <button
                      onClick={handlebtnClose}
                      className={classes.cancelbtn}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={AddVanityColor}
                      className={classes.sendbtn}
                    >
                      Add
                    </button>
                  </>
                ) : (
                  <p>...Loading</p>
                )}
              </div>
            </DialogActions>
          </Dialog>
        </div>
        {/*Edit popup*/}
        <div id="popup-register" className={classes.dialogtextaligncenter}>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            className={classes.textaligncenter}
            open={vanityColorOpenBtnEdit}
            onClose={handlebtnCloseedit}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Edit Vanity Tiles Color Details"}
            </DialogTitle>
            <DialogActions className={classes.textaligncenter}>
              <div className={classes.textalignbtn}>
                <input
                  value={vanityColorName}
                  onChange={(e) => setVanityColorName(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Color Name"
                ></input>
                <input
                  value={vanityColorCode}
                  onChange={(e) => setVanityColorCode(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Color Code"
                ></input>
                <input
                  value={vanityColorPrice}
                  onChange={(e) => setVanityColorPrice(e.target.value)}
                  className={classes.registertextfield}
                  type="number"
                  placeholder="Enter Color Price"
                ></input>
                <div>
                  <FormGroup className={classes.displycss}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={vanityColorChecked}
                          onChange={handleswitchVanityColor}
                          name="gilad"
                        />
                      }
                      label="IsDefault"
                    />
                  </FormGroup>
                </div>
                {!loader ? (
                  <>
                    <button
                      onClick={handlebtnCloseedit}
                      className={classes.cancelbtn}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={EditVanityColor}
                      className={classes.sendbtn}
                    >
                      Update
                    </button>
                  </>
                ) : (
                  <p>...Loading</p>
                )}
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </div>

      <div>
        <div className={classes.textaligncenter}>
          <Dialog
            className={classes.textaligncenter}
            open={accentColorOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
            <DialogContent>
              <DialogContentText
                className={classes.textaligncenter}
                id="alert-dialog-description"
              >
                Do you really want to delete this record? This process cannot be
                undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.textaligncenter}>
              {!loader ? (
                <div className={classes.textalignbtn}>
                  <button onClick={handleClose} className={classes.cancelbtn}>
                    Cancel
                  </button>
                  <button
                    onClick={DeleteAccentColor}
                    className={classes.deletebtn}
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <p>...Loading</p>
              )}
            </DialogActions>
          </Dialog>
        </div>
        <div id="popup-register" className={classes.dialogtextaligncenter}>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            className={classes.textaligncenter}
            open={accentColorOpenBtn}
            onClose={handlebtnClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {" Add Accent Tile Color Details "}
            </DialogTitle>

            <DialogActions className={classes.textaligncenter}>
              <div className={classes.textalignbtn}>
                <input
                  value={accentColorName}
                  onChange={(e) => setAccentColorName(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Color Name"
                ></input>
                <input
                  value={accentColorCode}
                  onChange={(e) => setAccentColorCode(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Color Code"
                ></input>
                <input
                  value={accentColorPrice}
                  onChange={(e) => setAccentColorPrice(e.target.value)}
                  className={classes.registertextfield}
                  type="number"
                  placeholder="Enter Price"
                ></input>
                <div>
                  <FormGroup className={classes.displycss}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={accentColorChecked}
                          onChange={handleswitchAccentColor}
                          name="gilad"
                        />
                      }
                      label="IsDefault"
                    />
                  </FormGroup>
                </div>

                {!loader ? (
                  <>
                    <button
                      onClick={handlebtnClose}
                      className={classes.cancelbtn}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={AddAccentColor}
                      className={classes.sendbtn}
                    >
                      Add
                    </button>
                  </>
                ) : (
                  <p>...Loading</p>
                )}
              </div>
            </DialogActions>
          </Dialog>
        </div>
        {/*Edit popup*/}
        <div id="popup-register" className={classes.dialogtextaligncenter}>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            className={classes.textaligncenter}
            open={accentColorOpenBtnEdit}
            onClose={handlebtnCloseedit}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Edit Accent Tiles Color Details"}
            </DialogTitle>
            <DialogActions className={classes.textaligncenter}>
              <div className={classes.textalignbtn}>
                <input
                  value={accentColorName}
                  onChange={(e) => setAccentColorName(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Color Name"
                ></input>
                <input
                  value={accentColorCode}
                  onChange={(e) => setAccentColorCode(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Color Code"
                ></input>
                <input
                  value={accentColorPrice}
                  onChange={(e) => setAccentColorPrice(e.target.value)}
                  className={classes.registertextfield}
                  type="number"
                  placeholder="Enter Color Price"
                ></input>
                <div>
                  <FormGroup className={classes.displycss}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={vanityColorChecked}
                          onChange={handleswitchAccentColor}
                          name="gilad"
                        />
                      }
                      label="IsDefault"
                    />
                  </FormGroup>
                </div>
                {!loader ? (
                  <>
                    <button
                      onClick={handlebtnCloseedit}
                      className={classes.cancelbtn}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={EditAccentColor}
                      className={classes.sendbtn}
                    >
                      Update
                    </button>
                  </>
                ) : (
                  <p>...Loading</p>
                )}
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </div>

      <div>
        <div className={classes.textaligncenter}>
          <Dialog
            className={classes.textaligncenter}
            open={wallColorOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
            <DialogContent>
              <DialogContentText
                className={classes.textaligncenter}
                id="alert-dialog-description"
              >
                Do you really want to delete this record? This process cannot be
                undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.textaligncenter}>
              {!loader ? (
                <div className={classes.textalignbtn}>
                  <button onClick={handleClose} className={classes.cancelbtn}>
                    Cancel
                  </button>
                  <button
                    onClick={DeleteWallColor}
                    className={classes.deletebtn}
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <p>...Loading</p>
              )}
            </DialogActions>
          </Dialog>
        </div>
        <div id="popup-register" className={classes.dialogtextaligncenter}>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            className={classes.textaligncenter}
            open={wallColorOpenBtn}
            onClose={handlebtnClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {" Add Wall Tile Color Details "}
            </DialogTitle>

            <DialogActions className={classes.textaligncenter}>
              <div className={classes.textalignbtn}>
                <input
                  value={wallColorName}
                  onChange={(e) => setWallColorName(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Color Name"
                ></input>
                <input
                  value={wallColorCode}
                  onChange={(e) => setWallColorCode(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Color Code"
                ></input>
                <input
                  value={wallColorPrice}
                  onChange={(e) => setWallColorPrice(e.target.value)}
                  className={classes.registertextfield}
                  type="number"
                  placeholder="Enter Price"
                ></input>
                <div>
                  <FormGroup className={classes.displycss}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={wallColorChecked}
                          onChange={handleswitchWallColor}
                          name="gilad"
                        />
                      }
                      label="IsDefault"
                    />
                  </FormGroup>
                </div>
                {!loader ? (
                  <>
                    <button
                      onClick={handlebtnClose}
                      className={classes.cancelbtn}
                    >
                      Cancel
                    </button>
                    <button onClick={AddWallColor} className={classes.sendbtn}>
                      Add
                    </button>
                  </>
                ) : (
                  <p>...Loading</p>
                )}
              </div>
            </DialogActions>
          </Dialog>
        </div>
        {/*Edit popup*/}
        <div id="popup-register" className={classes.dialogtextaligncenter}>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            className={classes.textaligncenter}
            open={wallColorOpenBtnEdit}
            onClose={handlebtnCloseedit}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Edit Wall Tiles Color Details"}
            </DialogTitle>
            <DialogActions className={classes.textaligncenter}>
              <div className={classes.textalignbtn}>
                <input
                  value={wallColorName}
                  onChange={(e) => setWallColorName(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Color Name"
                ></input>
                <input
                  value={wallColorCode}
                  onChange={(e) => setWallColorCode(e.target.value)}
                  className={classes.registertextfield}
                  type="text"
                  placeholder="Enter Color Code"
                ></input>
                <input
                  value={wallColorPrice}
                  onChange={(e) => setWallColorPrice(e.target.value)}
                  className={classes.registertextfield}
                  type="number"
                  placeholder="Enter Color Price"
                ></input>
                <div>
                  <FormGroup className={classes.displycss}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={wallColorChecked}
                          onChange={handleswitchWallColor}
                          name="gilad"
                        />
                      }
                      label="IsDefault"
                    />
                  </FormGroup>
                </div>
                {!loader ? (
                  <>
                    <button
                      onClick={handlebtnCloseedit}
                      className={classes.cancelbtn}
                    >
                      Cancel
                    </button>
                    <button onClick={EditWallColor} className={classes.sendbtn}>
                      Update
                    </button>
                  </>
                ) : (
                  <p>...Loading</p>
                )}
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
