import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb,
} from "assets/jss/material-dashboard-react.js";

const dashboardStyle = {
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px",
  },
  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
  },
  cardCategory: {
    color: grayColor[0],
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0",
  },
  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  headertitle: {
    marginBottom: "0",
    marginLeft: "40px",
    color: "#000",
    fontWeight: "400",
  },
  headertitlelogin: {
    marginLeft: "40px",
    color: "#000",
    fontWeight: "400",
    marginBottom: "30px",
  },
  notificationtitle: {
    marginTop: "0",
    fontSize: "15px",
    marginLeft: "42px",
    color: "#000",
    fontWeight: "400",
  },
  gridcontainer: {
    backgroundColor: "#eeeeee",
    height: "498px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    gap: "30px",
    flexWrap: "wrap",
  },
  cardui: {
    textAlign: "center",
    margin: "none",
    width: "260px",
  },
  cardheader: {
    fontSize: "60px",
    marginBottom: "0",
    fontWeight: "900",
    color: "#F8A11C",
  },
  cardheadermedile: {
    fontSize: "60px",
    marginBottom: "0",
    fontWeight: "900",
    color: "#000",
  },
  carddesc: {
    marginTop: "0",
    marginBottom: "30px",
    fontSize: "16px",
  },
  reporttag: {
    backgroundColor: "#eee",
    color: "#000",
    marginBottom: "0",
    boxShadow: "unset",
    width: "200px",
    marginLeft: "75px",
    marginTop: "65px",
    textAlign: "center",
    padding: "10px",
  },
  selectui: {
    position: "relative",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "36px",
    height: "30px",
    bottom: "-65px",
    width: "200px",
    textAlign: "center",
    border: "solid 2px #CDD1D4",
    padding: "20px",
  },
  notiicon: {
    width: "10px",
    marginRight: "5px",
  },
  loginbox: {
    backgroundColor: "#eee",
    width: "50%",
    textAlign: "center",
    marginLeft: "10%",
    padding: "46px",
  },
  logintxtfield: {
    width: "80%",
    margin: "25px",
  },
  btnlogin: {
    width: "60%",
    marginTop: "60px",
    marginBottom: "20px",
  },
  loginlink: {
    color: "#fff",
    padding: "10px",
    backgroundColor: "#ff9800",
    display: "block",
    width: "40%",
    margin: "0 auto",
  },
  ginuppagelink: {
    color: "#000",
    display: "block",
    marginTop: "20px",
  },
  contractfields: {
    // display: "block",
    // marginTop: "20px",
    // marginBottom: "20px",
    // padding: "7px",
    // width: "90%",
    padding: "10px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  smallfieldscontract: {
    width: "35%",
    padding: "7px",
    marginRight: "12px",
  },
  selectcontract: {
    width: "45%",
    padding: "8px",
    marginRight: "12px",
  },
  contractbtn: {
    height: "37px",
    color: "#fff",
    border: "none",
    padding: "10px",
    backgroundColor: "#F8A11C",
    width: "-webkit-fill-available",
    marginTop: "20px",
  },
  bordercontractright: {
    borderLeft: "solid 1px",
  },
  borderbottomcontract: {
    borderBottom: "solid 1px",
  },
  contracttextbox: {
    marginTop: "5%",
    padding: "10px",
  },
  container: {},
  h2contract: {
    textAlign: "center",
    marginTop: "100px",
    padding: "10px 0px",
    fontSize: "2.4em",
  },
  para24: {
    position: "absolute",
    right: "500px",
    padding: "40px 24px",
  },
  para25: {},
  para27: {
    textAlign: "center",
    padding: "80px",
  },
  para29: {
    position: "absolute",
    right: "300px",
    top: "2580px",
  },
  para30: {
    position: "absolute",
    right: "360px",
    top: "2635px",
  },
  loginimage: {
    marginTop: "40px",
    marginLeft: "15px",
  },
  leftsidermain: {
    gap: "50px",
    minWidth: "240px",
    maxWidth: "240px",
    display: "flex",
    alignItems: "center",
    borderRight: "solid 1px",
    flexDirection: "column",
    paddingLeft: "10px",
    paddingRight: "15px",
    paddingTop: "32px",
    maxHeight: "800px",
    backgroundColor: "white",
    minHeight: "600px",
  },
  smallfieldwrapper: {
    display: "flex",
    gap: "25px",
  },
  txtfieldwrapper: {
    display: "flex",
    flexDirection: "column",
    // gap: "15px",
  },
  smallfields: {
    width: "50%",
    padding: "10px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  acceptRejectBox: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    gap: "30px",
  },
  signaturePara: {
    display: "flex",
    gap: "10px",
    alignItems: "baseline",
    flexDirection: "column",
  },
  clearbtn: {
    color: "white",
    background: "#3bc73b",
    border: "none",
    width: "58px",
    height: "27px",
    borderRadius: "5px",
  },
  heading: {
    fontSize: "2.4em",
  },
  headerpara: {
  
  },

  "@media(max-width:665px) and (min-width:580px)":{
    headerpara:{
      whiteSpace: "nowrap",
      width: "110px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      // border: "1px solid #000000",
    }
 
  },
  "@media(max-width:579px) and (min-width:450px)":{
    headerpara:{
      whiteSpace: "nowrap",
      width: "80px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      // border: "1px solid #000000",
    }
 
  },
  "@media(max-width:449px) ":{
    headerpara:{
      whiteSpace: "nowrap",
      width: "60px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      // border: "1px solid #000000",
    }
 
  },
  "@media(max-width:768px) and (min-width:600px)": {
    heading: {
      fontSize: "2em",
    },
    h2contract: {
      fontSize: "2em",
    },
  },
  "@media(max-width:599px) ": {
    heading: {
      fontSize: "1.5em",
    },
    h2contract: {
      fontSize: "1.5em",
    },
  },
  "@media(max-width:768px)": {
    gridcontainer: {
      flexDirection: "column",
      height: "130vh",
      flexWrap: "nowrap",
      gap: "0px",
    },
    cardui: {
      width: "300px",
    },
    h2contract: {
      marginTop: "0px",
    },
    acceptRejectBox: {
      alignItems: "flex-end",
    },

  },

  "@media(max-width:570px) and (min-width:450px)": {
    reporttag: {
      width: "150px",
      marginLeft: "50px",
    },
    selectui: {
      width: "160px",
    },
  },
  "@media(max-width:449px)": {
    reporttag: {
      width: "140px",
      marginLeft: "0px",
    },
    selectui: {
      width: "160px",
      marginRight: "0px",
    },
  },
  "@media(max-width:600px)": {
    acceptRejectBox: {
      flexDirection: "column",
      gap: "0px",
      alignItems: "baseline",
    },
    notificationtitle: {
      textAlign: "center",
      marginLeft: "0px",
    },
    headertitle: {
      textAlign: "center",
      marginLeft: "0px",
    },
  },
  "@media(max-width:680px) and (min-width:590px)": {
    para27: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
  },
  "@media(max-width:589px) and (min-width:550px) ": {
    para27: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  "@media(max-width:549px) ": {
    para27: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
};

export default dashboardStyle;
