
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import notiicon from "assets/NotificationIcon.png";
import Menu from "@material-ui/core/Menu";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import morepic from "assets/Group 1.png";
import IconButton from "@material-ui/core/IconButton";

const options = [
  "Edit Configuration",
  "Edit Price",
  "Assign Sales Rep",
  "View Contract Form",
  "Customer Detail",
];
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  headertitle: {
    marginBottom: "0",
    marginLeft: "40px",
    color: "#000",
    fontWeight: "400",
  },
  notificationtitle: {
    marginTop: "0",
    fontSize: "15px",
    marginLeft: "42px",
    color: "#000",
    fontWeight: "400",
  },
  gridcontainer: {
    backgroundColor: "#eeeeee",
    height: "498px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardui: {
    textAlign: "center",
    margin: "none",
  },
  cardheader: {
    fontSize: "70px",
    marginBottom: "0",
    fontWeight: "900",
    color: "#F8A11C",
  },
  cardheadermedile: {
    fontSize: "70px",
    marginBottom: "0",
    fontWeight: "900",
    color: "#000",
  },
  carddesc: {
    marginTop: "0",
    marginBottom: "30px",
  },
  reporttag: {
    backgroundColor: "#eee",
    color: "#000",
    marginBottom: "0",
    boxShadow: "unset",
    width: "200px",
    marginLeft: "75px",
    marginTop: "65px",
    textAlign: "center",
    padding: "10px",
  },
  selectui: {
    position: "relative",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "36px",
    height: "30px",
    bottom: "-65px",
    width: "200px",
    textAlign: "center",
    border: "solid 2px #CDD1D4",
    padding: "20px",
  },
  notiicon: {
    width: "10px",
    marginRight: "5px",
  },
  customers: {
    fontFamily: "Arial, Helvetica, sans-serif",
    borderCollapse: "collapse",
    width: "100%",
  },
  customerstd: {
    border: "1px solid #ddd",
    padding: "8px",
  },
  tableUpgradeWrapper: {
    width: "100%",
    overflowX: "auto",
    backgroundColor: "#eee",
    MsOverflowStyle: "-ms-autohiding-scrollbar",
    WebkitOverflowScrolling: "touch",
    display: "flex",
    flexDirection: "column",
    padding: "2%",
  },
  table: {
    width: "100%",
    display: "table",
    marginTop: "4%",
    overflowX: "auto",
    borderColor: "grey",
    borderSpacing: "2px",
    borderCollapse: "collapse",
    backgroundColor: "white",
    "& thdead tr th": {
      fontSize: "1.063rem",
      padding: "12px 8px",
      verticalAlign: "middle",
      fontWeight: "300",
      borderTopWidth: "0",
      borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
      textAlign: "inherit",
    },
    "& tbody tr td": {
      padding: "12px 8px",
      verticalAlign: "middle",
      borderTop: "1px solid rgba(0, 0, 0, 0.06)",
    },
    "& td, & th": {
      display: "table-cell",
    },
  },
  center: {
    textAlign: "center",
  },
  centercircle: {
    textAlign: "center",
    position: "relative",
  },
  colortd: {
    padding: "10px",
    textAlign: "center",
    backgroundColor: "#CDD1D4",
    whiteSpace: "nowrap",
  },
  imgwidth: {
    width: "4px",
    marginLeft: "70px",
  },
  textaligncenter: {
    textAlign: "center",
  },
  textalignbtn: {
    textAlign: "center",
    width: "100%",
  },
  cancelbtn: {
    position: "relative",
    padding: "13px",
    width: "110px",
    marginRight: "10px",
    color: "#fff",
    backgroundColor: "#cdd1d4",
    border: "none",
    borderRadius: "5px",
  },
  deletebtn: {
    position: "relative",
    padding: "13px",
    width: "110px",
    marginRight: "10px",
    color: "#fff",
    backgroundColor: "#fd0e0e",
    border: "none",
    borderRadius: "5px",
  },
  sendbtn: {
    position: "relative",
    padding: "13px",
    width: "110px",
    marginRight: "10px",
    color: "#fff",
    backgroundColor: "#ff9800",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  registertextfield: {
    margin: "0 auto",
    display: "block",
    padding: "10px",
    marginBottom: "19px",
    width: "75%",
  },
  assignname: {
    fontSize: "16px",
    textAlign: "left",
    marginBottom: "20px",
    fontWeight: "400",
    paddingLeft: "12px",
  },
  assignnametitle: {
    padding: "12px",
    fontSize: "18px",
    textAlign: "left",
    fontWeight: "500",
  },
  loader: {
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    left: "0",
    right: "0",
    display: "block",
  },
  searchbox: {
    border: "none",
    display: "block",
    padding: "11px",
    fontSize: "14px",
    marginTop: "38px",
    borderRadius: "39px",
    width: "200px",
  },
  circleshow: {
    top: "10px",
    color: "#fff",
    width: "100px",
    padding: "2px",
    position: "absolute",
    fontSize: "12px",
    borderRadius: "11px",
    backgroundColor: "#32a55d",
    right: "0",
    left: "0",
    marginLeft: "auto",
    marginRight: "auto",
  },
  circleshow1: {
    top: "10px",
    color: "#fff",
    width: "100px",
    padding: "2px",
    position: "absolute",
    fontSize: "12px",
    borderRadius: "11px",
    backgroundColor: "#eb0606",
    right: "0",
    left: "0",
    marginLeft: "auto",
    marginRight: "auto",
  },
  userdetailtit: {
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "18px",
  },
  spanuserdetail: {
    marginLeft: "24px",
    fontWeight: "400",
  },
  "@media(max-width:570px) and (min-width:450px)": {
    reporttag: {
      width: "150px",
      marginLeft: "50px",
    },
    selectui: {
      width: "160px",
    },
  },
  "@media(max-width:449px)": {
    reporttag: {
      width: "140px",
      marginLeft: "0px",
    },
    selectui: {
      width: "160px",
      marginRight: "0px",
    },
  },
  "@media(max-width:600px)": {
    notificationtitle: {
      textAlign: "center",
      marginLeft: "0px",
    },
    headertitle: {
      textAlign: "center",
      marginLeft: "0px",
    },
  },
};

const useStyles = makeStyles(styles);
const ITEM_HEIGHT = 48;
export default function UserProfile() {
  const url = "https://bathroombackend.instantsolutionslab.site/api/";
  const classes = useStyles();
  const [age, setAge] = React.useState(0);
  const history = useHistory();
  const [allappointments, setallappointments] = React.useState([]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [openalert, setOpenalert] = React.useState(false);
  const [openassignval, setOpenassignval] = React.useState(false);

  const [openpopup, setOpenpopup] = React.useState(false);
  const [openpopupdetail, setOpenpopupdetail] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [discount, setDiscount] = React.useState("");
  const [customerdetail, setCustomerdetail] = React.useState("");
  const [appoinmentid, setAppoinmentid] = React.useState("");
  const [selectsales, setSelectsales] = React.useState("");
  const [allusers, setAllusers] = React.useState();
  const [discountid, setDiscountid] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [price, setEditprice] = React.useState("");

  const [userdetail, setUserdetail] = React.useState("");

  const handleClickalert = () => {
    setOpenalert(true);
  };
  const openassign = () => {
    setOpenassignval(true);
  };
  const [userinfo, setUserinfo] = React.useState("");
  React.useEffect(() => {
    setUserinfo(JSON.parse(localStorage.getItem("logininfo")));
    console.log(userinfo);
  }, []);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getallusers = () => {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    fetch(url + "get-all-users", {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data.data);
        setAllusers(data.data.data);
        openassign();
      })
      .catch(console.log);
  };


  const Assignrole = () => {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
  
    if (selectsales) {
      const data = {
        contact_number: customerdetail?.contact_number,
        email: customerdetail?.email,
        name: customerdetail?.name,
        price: customerdetail?.price,
      };
  
      // Ensure no empty or undefined fields
      if (!data.contact_number || !data.email || !data.name || !data.price) {
        setMessage("Incomplete customer details!");
        handleClickalert();
        return;
      }
  
      console.log("Assign Role Payload:", data);
  
      fetch("http://127.0.0.1:8000/api/customers", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((responseData) => {
          console.log("Server Response:", responseData);
  
          // Check for successful creation
          if (responseData.status || responseData.message === "User created successfully") {
            handleCloseassign();
            setMessage(responseData.message || "Assigned successfully!");
  
            // Update local state to mark as assigned
            setallappointments((prevAppointments) =>
              prevAppointments.map((appointment) =>
                appointment.email === customerdetail.email
                  ? { ...appointment, assigned: true }
                  : appointment
              )
            );
  
            handleClose();
          } else {
            console.error("Assignment failed:", responseData.message);
            setMessage(responseData.message || "Failed to assign role");
            handleClickalert();
          }
        })
        .catch((err) => {
          console.error("Error:", err);
          setMessage("An error occurred while assigning the role.");
          handleClickalert();
        });
    } else {
      setMessage("Please select a user!");
      handleClickalert();
    }
  };
  




  const savediscount = () => {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    if (discount !== "" && discount !== null && discount !== undefined) {
      const parsedPrice = parseFloat(price);
      const parsedDiscount = parseFloat(discount);

      if (isNaN(parsedPrice) || parsedPrice <= 0) {
        setMessage("Invalid price value");
        handleClickalert();
        return;
      }

      if (isNaN(parsedDiscount) || parsedDiscount < 0) {
        setMessage("Invalid discount value");
        handleClickalert();
        return;
      }


      const finalPrice = parsedPrice - parsedDiscount > 0 ? parsedPrice - parsedDiscount : 0;

      const payload = {
        price: finalPrice,
      };

      fetch(`http://127.0.0.1:8000/api/bathroom-users/${discountid}`, {
        method: "PUT",
        headers: new Headers({
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.message === "User updated successfully") {
            getallappointments();
            handleClosepoup();
            handleClose();
          } else {
            console.error(data.message);
          }
        })
        .catch((err) => console.error("Error updating user:", err));
    } else {
      setMessage("Please fill discount field");
      handleClickalert();
    }
  };

  const handleClickevent = (data, userinfo) => {
    console.log("data------>", data);
    console.log("user------>", userinfo);
    console.log("cutstomer------>", customerdetail);
    if (data == "Edit Configuration") {
      window.open("/admin/XcedLayout1", "_self");
    } if (data == "Edit Price") {
      console.log(userinfo.price);
      setEditprice(customerdetail.price);
      setDiscount(customerdetail.discount);
      console.log(price);
      setDiscountid(customerdetail.id);
      handleClickOpen();

    } else if (data == "Assign Sales Rep") {
      setAppoinmentid(customerdetail.id);
      console.log('====================================');
      console.log(customerdetail);
      console.log('====================================');
      getallusers();
    } else if (data == "View Contract Form") {
      localStorage.setItem("userappointmentid", userinfo.id);
      window.open("/admin/contractform", "_self");
    } else if (data == "Customer Detail") {
      console.log(data);
      let userdata = customerdetail;
      showuserdetail(userdata);
    }
  };

  const handleClickOpen = () => {
    setOpenpopup(true);
  };

  const handleClickOpendetail = () => {
    setOpenpopupdetail(true);
  };

  const handleClosepoup = () => {
    setOpenpopup(false);
  };

  const handleClosepoupdetail = () => {
    setOpenpopupdetail(false);
  };
  const handleCloseassign = () => {
    setOpenassignval(false);
  };
  React.useEffect(() => {
    getallappointments();
  }, []);

  const showuserdetail = (data) => {
    console.log(data);
    setUserdetail(data);
    handleClickOpendetail();
  };

  const searchfun = (data) => {
    console.log(data);
    if (data == "" || data.length < 1) {
      getallappointments();
    }
    if (data.length > 3) {
      console.log(data);
      searchalldata(data);
    }
  };

  function getallappointments() {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;

    fetch("http://127.0.0.1:8000/api/bathroom-users", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Appointments with Assignment Status:", data);
        setallappointments(data.reverse());
      })
      .catch(console.log);
  }



  function searchalldata(data) {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    fetch(url + "get-all-appintments?search=" + data, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallappointments(data.data.data);
      })
      .catch(console.log);
  }

  const setdatacustomer = (data) => {
    console.log(data);
    setCustomerdetail(data);
  };

  return (
    <div>
      <h3 className={classes.headertitle}>
        Welcome back {userinfo.user?.name}!
      </h3>
      <p className={classes.notificationtitle}>
        <img className={classes.notiicon} src={notiicon} />
        You have 2 appointments this week.
      </p>
      <GridContainer>
        <div className={classes.reporttag}>New Appointments</div>
        <Select
          className={classes.selectui}
          labelId="demo-select-small"
          id="demo-select-small"
          label="Age"
          value={age}
          onChange={handleChange}
        >
          <MenuItem value={0}>Filter by date</MenuItem>
          <MenuItem value={10}>This Week</MenuItem>
          <MenuItem value={20}>30 Days</MenuItem>
          <MenuItem value={30}>This Year</MenuItem>
        </Select>
        <div className={classes.tableUpgradeWrapper}>
          <input
            type="text"
            placeholder="Search..."
            className={classes.searchbox}
            onChange={(e) => searchfun(e.target.value)}
          />
          <div style={{ width: "100%", overflowX: "auto" }}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th className={classes.colortd}>Name </th>
                  <th className={classes.colortd}>Email</th>
                  <th className={classes.colortd}>Price</th>
                  <th className={classes.colortd}>Contract Number</th>
                  <th className={classes.colortd}>Status</th>
                </tr>
              </thead>

              <tbody>
                {/* {allappointments &&
                <CircularProgress className={classes.loader} color="inherit" />
              } */}
                {allappointments?.map((data, key) => {
                  return (
                    <tr key={key}>
                      <td className={classes.center}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p style={{ width: "100px" }}>
                            {data.name}
                          </p>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={function (event) {
                              setdatacustomer(data);
                              handleClick(event);
                            }}
                          >
                            <img className={classes.imgwidth} src={morepic} />
                          </IconButton>
                        </div>

                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: "20ch",
                            },
                          }}
                        >
                          {options.map((option) => (
                            <MenuItem
                              key={option}
                              onClick={() => handleClickevent(option, data)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </td>
                      <td className={classes.center}>
                        {data.price}
                      </td>
                      <td className={classes.center}>
                        {data.email}
                      </td>
                      <td className={classes.centercircle}>
                        ${data.contact_number}

                      </td>
                      <td className={classes.centercircle}>
                        {data.assigned ? (
                          <div className={classes.circleshow}>Assigned</div>
                        ) : (
                          <div className={classes.circleshow1}>Unassigned</div>
                        )}
                      </td>

                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className={classes.textaligncenter}>
            <Dialog
              className={classes.textaligncenter}
              open={openpopupdetail}
              onClose={handleClosepoup}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Customer Detail"}
              </DialogTitle>

              <DialogActions className={classes.textaligncenter}>
                <div className={classes.textalignbtn}>
                  <p className={classes.userdetailtit}>
                    Customer Name:{" "}
                    <span className={classes.spanuserdetail}>
                      {userdetail.name}{" "}
                      {/* {userdetail.name} */}
                    </span>
                  </p>

                  <p className={classes.userdetailtit}>
                    Customer Phone:{" "}
                    <span className={classes.spanuserdetail}>
                      {userdetail.contact_number}
                    </span>
                  </p>
                  <p className={classes.userdetailtit}>
                    Customer Email:{" "}
                    <span className={classes.spanuserdetail}>
                      {userdetail.email}
                    </span>
                  </p>
                  <p className={classes.userdetailtit}>
                    Price:{" "}
                    <span className={classes.spanuserdetail}>
                      {/* {userdetail.sales?.price}{" "} */}
                      {userdetail.price}
                    </span>
                  </p>
                  <br />
                  <button
                    onClick={handleClosepoupdetail}
                    className={classes.sendbtn}
                  >
                    Ok
                  </button>
                </div>
              </DialogActions>
            </Dialog>
          </div>
          <div className={classes.textaligncenter}>
            <Dialog
              className={classes.textaligncenter}
              open={openpopup}
              onClose={handleClosepoup}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Edit Price"}</DialogTitle>
              <DialogContent>
                <DialogContentText
                  className={classes.textaligncenter}
                  id="alert-dialog-description"
                >
                  You can add discount to make your client happy!
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.textaligncenter}>
                <div className={classes.textalignbtn}>
                  <p className="pprice">Total Price: {price}</p>
                  <input
                    value={discount} // Bind state to input field
                    onChange={(e) => setDiscount(e.target.value)}
                    className={classes.registertextfield}
                    type="number"
                    placeholder="Discount"
                  />
                  <p className="ppprice">
                    Final Price: {price - (discount || 0) > 0 ? price - discount : 0}
                  </p>
                  <button
                    onClick={handleClosepoup}
                    className={classes.cancelbtn}
                  >
                    Cancel
                  </button>
                  <button onClick={savediscount} className={classes.sendbtn}>
                    Save
                  </button>
                </div>
              </DialogActions>
            </Dialog>
          </div>
          <div className={classes.textaligncenter}>
            <Dialog
              className={classes.textaligncenter}
              open={openassignval}
              onClose={handleCloseassign}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Assign Sale"}</DialogTitle>
              <DialogContent>
                <DialogContentText
                  className={classes.textaligncenter}
                  id="alert-dialog-description"
                >
                  Assign request to sale representative
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.textaligncenter}>
                <div className={classes.textalignbtn}>
                  <div className={classes.assignnametitle}>
                    Select Users Name
                  </div>
                  {allusers?.map((data, key) => {
                    return (
                      <div
                        className={
                          selectsales == data.id
                            ? "selectassign"
                            : "unselectassign"
                        }
                        onClick={() => setSelectsales(data.id)}
                        key={key}
                      >
                        {data.name}
                      </div>
                    );
                  })}
                  <div className="space"></div>
                  <button
                    onClick={handleCloseassign}
                    className={classes.cancelbtn}
                  >
                    Cancel
                  </button>
                  <button onClick={Assignrole} className={classes.sendbtn}>
                    Assign
                  </button>
                </div>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <Snackbar
          autoHideDuration={900}
          open={openalert}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          // onClose={handleClose}
          message={message}
          onClose={() => setOpenalert(false)}
        />
      </GridContainer>
    </div>
  );
}