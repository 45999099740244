import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// import Unarchive from "@material-ui/icons/Unarchive";
// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import XcedLayout1Page from "views/XcedLayout1/XcedLayout1.js";
import modalconfigratorsettingPage from "views/modalconfigratorsetting/modalconfigratorsetting.js";
import SalesRepControlsPage from "views/SalesRepControls/SalesRepControls.js";
import ReportPage from "views/Report/Report.js";
import ContractformPage from "views/Contractform/Contractform.js";
import LoginPage from "views/Login/Login.js";
import SignupPage from "views/Signup/Signup.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Allemploy from "views/Allemploy/Allemploy.js";
// import Icons from "views/Icons/Icons.js";
// import Maps from "views/Maps/Maps.js";
// import NotificationsPage from "views/Notifications/Notifications.js";
// import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
// import RTLPage from "views/RTLPage/RTLPage.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/appointmentrequests",
    name: "Appointment Requests",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
  },
 
  {
    path: "/signedcontracts",
    name: "Appointment",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/customerlist",
    name: "Customer List",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: Allemploy,
    layout: "/admin",
  },
  {
    path: "/salesrepcontrols",
    name: "Sales Rep",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: SalesRepControlsPage,
    layout: "/admin",
  },
  {
    path: "/modalconfigratorsetting",
    name: "Modal Configurator",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: modalconfigratorsettingPage,
    layout: "/admin",
  },
  {
    path: "/report",
    name: "Reports",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: ReportPage,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: LoginPage,
    layout: "/admin",
  },
  {
    path: "/contractform",
    name: "Contractform",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: ContractformPage,
    layout: "/admin",
  },
  {
    path: "/signup",
    name: "Signup",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: SignupPage,
    layout: "/admin",
  },
  {
    path: "#",
    name: "Logout",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/XcedLayout1",
    name: "Xced Layout1",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: XcedLayout1Page,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin",
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL Support",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Language,
  //   component: RTLPage,
  //   layout: "/rtl",
  // },
  // {
  //   path: "/upgrade-to-pro",
  //   name: "Upgrade To PRO",
  //   rtlName: "التطور للاحترافية",
  //   icon: Unarchive,
  //   component: UpgradeToPro,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;
