import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import mainimg from "assets/luxury-bathroom-visualisation 1.png";
import logo from "assets/Xced Logo 1.png";
import modelimg from "assets/image 12 3 (1).png";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import layout from "assets/Group 14 (1).png";
import Groupimg from "assets/Group.png";
import shapeimg from "assets/Rectangle 9.png";
import connectcheck from "assets/correct 1.png";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const styles = {
  title: {
    fontWeight: "600",
    fontSize: "40px",
    marginLeft: "5%",
    zIndex: "99999999999",
  },
  pagename: {
    color: "#F8A11C",
    fontSize: "16px",
    fontWeight: "600",
    position: "relative",
    marginTop: "40px",
    marginLeft: "12%",
  },
  header: {
    display: "flex",
    marginTop: "-25px",
    marginBottom: "48px",
  },
  mainimage: {
    width: "95%",
    marginLeft: "3%",
  },
  pricebox: {
    background: "#FFFFFF",
    boxShadow: "0px 10px 30px rgb(26 26 26 / 16%)",
    borderRadius: "12px",
    width: "80%",
    position: "relative",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "22px",
    fontWeight: "400",
    fontSize: "18px",
  },
  pricebox1: {
    background: "#000",
    color: "#fff",
    // boxShadow: "0px 10px 30px rgb(26 26 26 / 16%)",
    borderRadius: "12px",
    width: "80%",
    position: "relative",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "22px",
    fontWeight: "400",
    fontSize: "18px",
    textAlign: "center",
    marginTop: "15px",
  },
  pricebox2: {
    background: "#F8A11C",
    // boxShadow: "0px 10px 30px rgb(26 26 26 / 16%)",
    color: "#fff",
    borderRadius: "12px",
    width: "80%",
    position: "relative",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "22px",
    fontWeight: "400",
    fontSize: "18px",
    textAlign: "center",
    marginTop: "15px",
    marginBottom: "15px",
  },
  priceamount: {
    float: "right",
    fontWeight: "700",
  },
  modeltitle: {
    fontWeight: "600",
    fontSize: "24px",
    marginLeft: "8%",
  },
  subVanitytitle: {
    fontWeight: "600",
    fontSize: "14px",
    marginLeft: "8%",
  },
  subcircletitle: {
    fontWeight: "600",
    fontSize: "14px",
  },
  modalimg: {
    height: "85px",
    borderRadius: "20px",
    position: "relative",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  modalimgshape: {
    border: "solid 1px #E6E6E6",
    borderRadious: "#F5F5F5",
    height: "115px",
    borderRadius: "20px",
    position: "relative",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  modalPlumbingshape: {
    border: "solid 1px #E6E6E6",
    borderRadious: "#F5F5F5",
    height: "180px",
    borderRadius: "20px",
    position: "relative",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  Optiondesign: {
    position: "absolute",
    marginTop: "-72px",
    marginLeft: "48px",
    color: "#fff",
    fontWeight: "600",
    fontSize: "16px",
  },
  optionspan: {
    fontWeight: "600",
    fontSize: "40px",
    marginTop: "7.9px",
    display: "block",
  },
  optionbox: {
    marginBottom: "10px",
  },
  shapeimge: {
    width: "125px",
    display: "inline-block",
    marginTop: "15px",
    marginLeft: "30px",
  },
  titleshape: {
    marginTop: "-59px",
    display: "block",
    marginLeft: "200px",
    fontWeight: "600",
    fontSize: "22px",
  },
  checkimg: {
    width: "35px",
    marginTop: "-50px",
    display: "block",
    float: "right",
    marginRight: "28px",
  },
  circle: {
    height: "70px",
    background: "#000",
    width: "70px",
    borderRadius: "50px",
  },
  colorcircle: {
    textAlign: "center",
    marginRight: "15px",
  },
  maincirlediv: {
    marginLeft: "8%",
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  accessoriesbtn: {
    border: "solid 1px #F8A11C",
    width: "190px",
    textAlign: "center",
    height: "50px",
    borderRadius: "10px",
    marginLeft: "8%",
    backgroundColor: "#F8A11C",
    display: "inline-Block",
    marginRight: "12px",
  },
  accessoriesbtn2: {
    border: "solid 1px #F8A11C",
    width: "190px",
    textAlign: "center",
    height: "50px",
    borderRadius: "10px",
    display: "inline-Block",
    backgroundColor: "#F8A11C",
  },
  accessoriesbtntitle: {
    textAlign: "left",
    marginLeft: "14px",
    marginTop: "12px",
    fontWeight: "600",
    color: "#fff",
    display: "inline-block",
  },
  accessoriescheck: {
    display: "inline-Block",
    width: "25px",
    float: "right",
    marginTop: "13px",
    marginRight: "14px",
  },
  togletitle: {
    position: "relative",
    top: "17px",
    color: "#5A6670",
    fontWeight: "400",
    fontSize: "16px",
    marginLeft: "9%",
  },
  toglefic: {
    marginTop: "12px",
  },
  c: {
    float: "right",
    marginRight: "45px",
    marginTop: "18px",
    minWidth: "56px",
  },
  electricaltitle: {
    display: "block",
    fontSize: "22px",
    marginTop: "20px",
    fontWeight: "600",
    marginLeft: "30px",
  },
  electricaldetail: {
    color: "#5A6670",
    fontWeight: "400",
    fontSize: "16px",
    width: "90%",
    marginLeft: "30px",
    marginTop: -"17px",
  },
  ulchange: {
    marginTop: "-12px !important",
  },
  boxspan: {
    fontWeight: "600",
    fontSize: "24px",
    float: "right",
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  width66: {
    width: "66.66%",
  },
  width33: {
    width: "33.33%",
  },
  plumbingTxtBtn: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  plumbTxt: {
    color: "#5A6670",
    fontSize: "16px",
    fontWeight: "400",
    marginLeft: "9%",
    width: "310px",
  },
  togle: {
    minWidth: "56px",
  },
  acesbtnbox: {
    display: "flex",
  },
  bottmbtn:{
    display:"flex",
    flexDirection:"column"
  },
  "@media(max-width:960px)": {
    mainWrapper: {
      flexDirection: "column",
    },
    width66: {
      width: "100%",
    },
    width33: {
      // padding: "20px",
      marginTop: "20px",
    },
  },
  "@media(max-width:960px) and (min-width:768px)": {
    width33: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    optionbox: {
      width: "600px",
    },
    pricebox1: {
      width: "600px",
    },
    pricebox2: {
      width: "600px",
    },
    pricebox: {
      width: "600px",
    },
    plumbTxt:{
      width:"600px"
    }
  },
  "@media(max-width:767px) and (min-width:600px)": {
    optionbox: {
      width: "500px",
    },
    pricebox1: {
      width: "500px",
    },
    pricebox2: {
      width: "500px",
    },
    pricebox: {
      width: "500px",
    },
    plumbTxt:{
      width:"500px"
    }
  },
  "@media(max-width:599px) and (min-width:450px)": {
    optionbox: {
      width: "380px",
    },
    pricebox1: {
      width: "380px",
    },
    pricebox2: {
      width: "380px",
    },
    pricebox: {
      width: "380px",
    },
    plumbTxt:{
      width:"380px"
    }
  },
  "@media(max-width:449px)": {
    optionbox: {
      width: "300px",
    },
    pricebox1: {
      width: "300px",
    },
    pricebox2: {
      width: "300px",
    },
    pricebox: {
      width: "300px",
    },
    plumbTxt:{
      width:"260px"
    }
  },
  "@media(max-width:767px)": {
    width33: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  "@media(max-width:500px)": {
    acesbtnbox: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "20px",
    },
    accessoriesbtn: {
      marginRight: "0%",
      marginLeft: "0%",
    },
  },
};
const useStyles = makeStyles(styles);
export default function XcedLayout1() {
  const url = "https://bathroombackend.instantsolutionslab.site/api/";
  const classes = useStyles();
  const [show, setShow] = React.useState("");
  const [show1, setShow1] = React.useState("");
  const [show2, setShow2] = React.useState("");
  const handleClickOpen = () => {
    console.log(show);
    if (show == "true") {
      setShow("false");
    } else {
      setShow("true");
    }
  };
  const handleClickOpen1 = () => {
    console.log(show);
    if (show1 == "true") {
      setShow1("false");
    } else {
      setShow1("true");
    }
  };
  const handleClickOpen2 = () => {
    console.log(show);
    if (show2 == "true") {
      setShow2("false");
    } else {
      setShow2("true");
    }
  };

  const redirectd = () => {
    window.open("/admin/dashboard", "_self");
  };
  return (
    <div>
      <div className={classes.header}>
        <h3 onClick={redirectd} className={classes.title}>
          xcēd
        </h3>
        <p className={classes.pagename}>Rectangle | Layout 1</p>
      </div>
      <Grid container spacing={2} className={classes.mainWrapper}>
        <div className={classes.width66} id="doon">
          {/* <img className={classes.mainimage} src={mainimg} /> */}
          {/* <div id="canvas-container"></div> */}
        </div>
        <Grid className={classes.width33}>
          <div className={classes.pricebox}>
            PRICE: <span className={classes.boxspan}>$50,000</span>
          </div>
          <h3 className={classes.modeltitle}>Bathroom Model</h3>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={classes.optionbox}>
              <img
                className={classes.modalimg}
                src={modelimg}
                style={{ width: "-webkit-fill-available" }}
              />
              <p className={classes.Optiondesign}>
                OPTION 1 <br /> <span className={classes.optionspan}>Glam</span>
              </p>
            </div>
            <div className={classes.optionbox}>
              <img
                className={classes.modalimg}
                src={modelimg}
                style={{ width: "-webkit-fill-available" }}
              />
              <p className={classes.Optiondesign}>
                OPTION 1 <br /> <span className={classes.optionspan}>Glam</span>
              </p>
            </div>
            <div className={classes.optionbox}>
              <img
                className={classes.modalimg}
                src={modelimg}
                style={{ width: "-webkit-fill-available" }}
              />
              <p className={classes.Optiondesign}>
                OPTION 1 <br /> <span className={classes.optionspan}>Glam</span>
              </p>
            </div>
            <div className={classes.optionbox}>
              <img
                className={classes.modalimg}
                src={modelimg}
                style={{ width: "-webkit-fill-available" }}
              />
              <p className={classes.Optiondesign}>
                OPTION 1 <br /> <span className={classes.optionspan}>Glam</span>
              </p>
            </div>
          </div>

          <h3 className={classes.modeltitle}>Floor plan</h3>
          <div onClick={() => handleClickOpen()} className={classes.optionbox}>
            <div className={classes.modalimgshape}>
              <img className={classes.shapeimge} src={layout} />
              <p className={classes.titleshape}>Layout one</p>
              {show == "true" && (
                <img className={classes.checkimg} src={Groupimg} />
              )}
            </div>
          </div>
          <div onClick={() => handleClickOpen1()} className={classes.optionbox}>
            <div className={classes.modalimgshape}>
              <img className={classes.shapeimge} src={layout} />
              <p className={classes.titleshape}>Layout one</p>
              {show1 == "true" && (
                <img className={classes.checkimg} src={Groupimg} />
              )}
            </div>
          </div>
          <div onClick={() => handleClickOpen2()} className={classes.optionbox}>
            <div className={classes.modalimgshape}>
              <img className={classes.shapeimge} src={layout} />
              <p className={classes.titleshape}>Layout one</p>
              {show2 == "true" && (
                <img className={classes.checkimg} src={Groupimg} />
              )}
            </div>
          </div>
          <h3 className={classes.modeltitle}>Vanity & More</h3>
          <h5 className={classes.subVanitytitle}>COLOR</h5>
          <div className={classes.maincirlediv}>
            <div className={classes.colorcircle}>
              <div className={classes.circle}></div>
              <p className={classes.subcircletitle}>Black</p>
            </div>
            <div className={classes.colorcircle}>
              <div className={classes.circle}></div>
              <p className={classes.subcircletitle}>White</p>
            </div>
            <div className={classes.colorcircle}>
              <div className={classes.circle}></div>
              <p className={classes.subcircletitle}>Oak</p>
            </div>
          </div>
          <h5 className={classes.subVanitytitle}>Accessories</h5>
          <div className={classes.acesbtnbox}>
            <div className={classes.accessoriesbtn}>
              <p className={classes.accessoriesbtntitle}>Accessories</p>{" "}
              <img className={classes.accessoriescheck} src={connectcheck} />
            </div>
            <div className={classes.accessoriesbtn2}>
              <p className={classes.accessoriesbtntitle}>Mirror</p>{" "}
              <img className={classes.accessoriescheck} src={connectcheck} />
            </div>
          </div>

          <div className={classes.plumbingTxtBtn}>
            <p className={classes.plumbTxt}>I want a medicine cabinet</p>
            <input type="checkbox" className={classes.togle} />
          </div>
          <h5 className={classes.subVanitytitle}>Electrical</h5>
          <div onClick={() => handleClickOpen()} className={classes.optionbox}>
            <div className={classes.modalimgshape}>
              <p className={classes.electricaltitle}>Package 1</p>
              {show == "true" && (
                <img className={classes.checkimg} src={Groupimg} />
              )}
              <p className={classes.electricaldetail}>
                Above mirror, Center fixture, Dimmable switching, Exhaust fan
                (if app.)
              </p>
            </div>
          </div>
          <div className={classes.optionbox}>
            <div className={classes.modalimgshape}>
              <p className={classes.electricaltitle}>Package 2</p>
              <img className={classes.checkimg} src={Groupimg} />
              <p className={classes.electricaldetail}>
                Sconces, Can lights (if app.), Dimmable switching, Exhaust fan
                (if app.)
              </p>
            </div>
          </div>
          <div className={classes.plumbingTxtBtn}>
            <p className={classes.plumbTxt}>Heated seat, bidet combo</p>
            <input type="checkbox" className={classes.togle} />
          </div>
          <br />
          <div className={classes.plumbingTxtBtn}>
            <p className={classes.plumbTxt}>Heated flooring</p>
            <input type="checkbox" className={classes.togle} />
          </div>
          <h3 className={classes.modeltitle}>Tiles</h3>
          <h5 className={classes.subVanitytitle}>FLOOR TILE</h5>
          <div className={classes.maincirlediv}>
            <div className={classes.colorcircle}>
              <div className={classes.circle}></div>
              <p className={classes.subcircletitle}>Gray</p>
            </div>
            <div className={classes.colorcircle}>
              <div className={classes.circle}></div>
              <p className={classes.subcircletitle}>Black</p>
            </div>
            <div className={classes.colorcircle}>
              <div className={classes.circle}></div>
              <p className={classes.subcircletitle}>Beige</p>
            </div>
          </div>
          <h5 className={classes.subVanitytitle}>WALL TILE</h5>
          <div className={classes.maincirlediv}>
            <div className={classes.colorcircle}>
              <div className={classes.circle}></div>
              <p className={classes.subcircletitle}>Gray</p>
            </div>
            <div className={classes.colorcircle}>
              <div className={classes.circle}></div>
              <p className={classes.subcircletitle}>White</p>
            </div>
            <div className={classes.colorcircle}>
              <div className={classes.circle}></div>
              <p className={classes.subcircletitle}>Cappuccino</p>
            </div>
          </div>
          <h5 className={classes.subVanitytitle}>ACCENT TILE</h5>
          <div className={classes.maincirlediv}>
            <div className={classes.colorcircle}>
              <div className={classes.circle}></div>
              <p className={classes.subcircletitle}>Honey</p>
            </div>
            <div className={classes.colorcircle}>
              <div className={classes.circle}></div>
              <p className={classes.subcircletitle}>Stone</p>
            </div>
          </div>
          <h3 className={classes.modeltitle}>Plumbing</h3>
          <div className={classes.optionbox}>
            <div className={classes.modalPlumbingshape}>
              <p className={classes.electricaltitle}>Package 1</p>
              <img className={classes.checkimg} src={Groupimg} />
              <p className={classes.electricaldetail}>
                <ul className={classes.ulchange}>
                  <li>Tub</li>
                  <li>Toilet</li>
                  <li>Shower Fixtures</li>
                  <li>Lav and Faucet combo</li>
                </ul>
              </p>
            </div>
          </div>
          <div className={classes.optionbox}>
            <div className={classes.modalPlumbingshape}>
              <p className={classes.electricaltitle}>Package 2</p>
              <img className={classes.checkimg} src={Groupimg} />
              <p className={classes.electricaldetail}>
                <ul className={classes.ulchange}>
                  <li>Shower pan base with glass door and panel</li>
                  <li>Toilet</li>
                  <li>Shower Fixtures</li>
                  <li>Lav and Faucet combo</li>
                </ul>
              </p>
            </div>
          </div>
          <div className={classes.optionbox}>
            <div className={classes.modalPlumbingshape}>
              <p className={classes.electricaltitle}>Package 3</p>
              <img className={classes.checkimg} src={Groupimg} />
              <p className={classes.electricaldetail}>
                <ul className={classes.ulchange}>
                  <li>Shower tile base with glass door</li>
                  <li>Toilet</li>
                  <li>Shower Fixtures</li>
                  <li>Lav and Faucet combo</li>
                </ul>
              </p>
            </div>
          </div>
          <h3 className={classes.modeltitle}>Plumbing upgrades</h3>
          <div className={classes.plumbingTxtBtn}>
            <p className={classes.plumbTxt}>
              Convert existing tub to shower or vice versa
            </p>
            <input type="checkbox" className={classes.togle} />
          </div>
          <br />
          <div className={classes.plumbingTxtBtn}>
            <p className={classes.plumbTxt}>Upgrade to shower handheld</p>
            <input type="checkbox" className={classes.togle} />
          </div>
          <div className={classes.plumbingTxtBtn}>
            <p className={classes.plumbTxt}>Upgrade to handheld and overhead</p>
            <input type="checkbox" className={classes.togle} />
          </div>
          <div className={classes.plumbingTxtBtn}>
            <p className={classes.plumbTxt}>Upgrade wall mount faucet</p>
            <input type="checkbox" className={classes.togle} />
          </div>
          <div className={classes.plumbingTxtBtn}>
            <p className={classes.plumbTxt}>Add half panel glass to tub</p>
            <input type="checkbox" className={classes.togle} />
          </div>
          <br />
          <div className={classes.bottmbtn}>
            <div className={classes.pricebox1}>SAVE FOR LATER</div>
            <div className={classes.pricebox2}>COMPLETE</div>
            <div className={classes.pricebox}>
              PRICE: <span className={classes.boxspan}>$50,000</span>
            </div>
          </div>
        </Grid>
      </Grid>
      <GridContainer></GridContainer>
    </div>
  );
}
