import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "components/Grid/GridItem.js";
import SignatureCanvas from "react-signature-canvas";
import logo from "assets/Xced Logo 1.png";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function Contractform() {
  const signatureRef1 = useRef(null);
  const signatureRef2 = useRef(null);
  const signatureRef3 = useRef(null);
  const signatureRef4 = useRef(null);

  const url = "https://bathroombackend.instantsolutionslab.site/api/";
  const classes = useStyles();
  const sidebar = useMediaQuery("(max-width:960px)");
  const [appointmentid, setAppointmentid] = React.useState("");
  const [contractdata, setContractdata] = React.useState("");
  const [contractdata1, setContractdata1] = React.useState("");
  const [showSidebar, setShowSidebar] = React.useState(false);
  const [input1, setInput1] = React.useState("");
  const [input2, setInput2] = React.useState("");
  const [input3, setInput3] = React.useState("");
  const [input4, setInput4] = React.useState("");
  const [input5, setInput5] = React.useState("");
  const [input6, setInput6] = React.useState("");
  const [input7, setInput7] = React.useState("");
  const [input8, setInput8] = React.useState("");
  const [input9, setInput9] = React.useState("");
  const [input10, setInput10] = React.useState("");
  const [input11, setInput11] = React.useState("");
  const [input12, setInput12] = React.useState("");
  const [input13, setInput13] = React.useState("");
  const [input14, setInput14] = React.useState("");
  const [input15, setInput15] = React.useState("");
  const [input16, setInput16] = React.useState("");
  const [input17, setInput17] = React.useState("");
  const [input18, setInput18] = React.useState("");
  const [input19, setInput19] = React.useState("");
  const handleClear1 = () => {
    signatureRef1.current.clear();
  };
  const handleClear2 = () => {
    signatureRef2.current.clear();
  };
  const handleClear3 = () => {
    signatureRef3.current.clear();
  };
  const handleClear4 = () => {
    signatureRef4.current.clear();
  };
  React.useEffect(() => {
    callappoinmentid();
  }, []);
  const callappoinmentid = () => {
    var appointment = localStorage.getItem("userappointmentid");
    setAppointmentid(appointment);
    console.log(appointment);
  };

  const getcontracthtml = () => {
    // alert(input1);
    setContractdata("temp");
    var temp = document.getElementById("contractform");
    console.log(temp);
    setContractdata1(temp);
    setTimeout(function () {
      console.log(contractdata1);
      sendcontract();
    }, 1000);
  };

  const sendcontract = () => {
    let usertoken = JSON.parse(localStorage.getItem("logininfo")).token;
    // console.log(usertoken);
    const data = new FormData();
    data.append("appointment_id", appointmentid);
    data.append("data", contractdata1);
    fetch(url + "add-contract", {
      method: "POST",
      body: data,
      headers: new Headers({
        Authorization: "Bearer " + usertoken,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch(console.log);
  };
  const redirecttodashboard = () => {
    window.open("/admin/dashboard", "_self");
  };
  const handleOpen = () => {
    setShowSidebar(!showSidebar);
    // console.log("hello");
    // alert("hi");
  };
  return (
    <div id="main-div" style={{ zIndex: 9999 }}>
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <Grid
          className={classes.bordercontractright}
          style={{
            paddingLeft: "9px",
            paddingRight: "0px",
          }}
        >
          <Grid
            className={classes.borderbottomcontract}
            style={{
              paddingLeft: "9px",
              paddingRight: "0px",
              justifyContent: "center",
              alignItems: "center",
              zindex: 9999,
            }}
            container
            spacing={2}
          >
            {sidebar && (
              <Grid style={{ zIndex: 9999 }} xs={1}>
                <IconButton onClick={handleOpen}>
                  <MenuIcon />
                </IconButton>
              </Grid>
            )}

            <Grid xs={3}>
              <p className={classes.headerpara}>Bathroom Model Modern</p>
            </Grid>
            <Grid xs={3}>
              <p className={classes.headerpara}>
                5565 Main St. Dallas, TX 75214
              </p>
            </Grid>
            <Grid xs={3}>
              <p className={classes.headerpara}>Configured Price:$16,443</p>
            </Grid>
            {showSidebar && (
              <Grid style={{ zIndex: 9999 }} xs={1}>
                <IconButton onClick={handleOpen}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
          <div
            style={{
              display: "flex",
              marginTop: "6px",
              borderTop: "2px solid",
            }}
          >
            {!sidebar && showSidebar === false ? (
              <div className={classes.leftsidermain}>
                <img
                  onClick={redirecttodashboard}
                  src={logo}
                  width={146}
                  height={68}
                />

                <div className={classes.txtfieldwrapper}>
                  <input
                    className={classes.contractfields}
                    type="text"
                    placeholder="Name"
                  ></input>

                  <input
                    className={classes.contractfields}
                    type="text"
                    placeholder="Billing Address"
                  ></input>
                  <div className={classes.smallfieldwrapper}>
                    <select
                      name="State"
                      id="State"
                      className={classes.smallfields}
                    >
                      <option value="State">State</option>
                      <option value="saab">Saab</option>
                      <option value="mercedes">Mercedes</option>
                      <option value="audi">Audi</option>
                    </select>
                    <input
                      type="text"
                      placeholder="Zip Code"
                      className={classes.smallfields}
                    ></input>
                  </div>
                  <input
                    className={classes.contractfields}
                    type="text"
                    placeholder="Credit Card Number"
                  ></input>
                  <div className={classes.smallfieldwrapper}>
                    <input
                      type="text"
                      placeholder="XX / XX"
                      className={classes.smallfields}
                    ></input>
                    <input
                      type="text"
                      placeholder="CVC"
                      className={classes.smallfields}
                    ></input>
                  </div>

                  <input
                    className={classes.contractfields}
                    type="text"
                    placeholder="Total Charged"
                  ></input>

                  <button
                    onClick={getcontracthtml}
                    className={classes.contractbtn}
                  >
                    PLACE ORDER
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            {showSidebar && (
              <div
                className={classes.leftsidermain}
                style={{
                  position: "absolute",
                  borderBottom: "1px solid",
                  paddingBottom: "32px",
                }}
              >
                <img
                  onClick={redirecttodashboard}
                  src={logo}
                  width={146}
                  height={68}
                />

                <div className={classes.txtfieldwrapper}>
                  <input
                    className={classes.contractfields}
                    type="text"
                    placeholder="Name"
                  ></input>

                  <input
                    className={classes.contractfields}
                    type="text"
                    placeholder="Billing Address"
                  ></input>
                  <div className={classes.smallfieldwrapper}>
                    <select
                      name="State"
                      id="State"
                      className={classes.smallfields}
                    >
                      <option value="State">State</option>
                      <option value="saab">Saab</option>
                      <option value="mercedes">Mercedes</option>
                      <option value="audi">Audi</option>
                    </select>
                    <input
                      type="text"
                      placeholder="Zip Code"
                      className={classes.smallfields}
                    ></input>
                  </div>
                  <input
                    className={classes.contractfields}
                    type="text"
                    placeholder="Credit Card Number"
                  ></input>
                  <div className={classes.smallfieldwrapper}>
                    <input
                      type="text"
                      placeholder="XX / XX"
                      className={classes.smallfields}
                    ></input>
                    <input
                      type="text"
                      placeholder="CVC"
                      className={classes.smallfields}
                    ></input>
                  </div>

                  <input
                    className={classes.contractfields}
                    type="text"
                    placeholder="Total Charged"
                  ></input>

                  <button
                    onClick={getcontracthtml}
                    className={classes.contractbtn}
                  >
                    PLACE ORDER
                  </button>
                </div>
              </div>
            )}
            <div id="contractform" className={classes.contracttextbox}>
              <div className={classes.container}>
                <div style={{ width: "100%", paddingLeft: "12px" }}>
                  <h2 className={classes.h2contract}>
                    <u>REMODELING AGREEMENT</u>
                  </h2>
                  <p className={classes.para}>
                    This Remodeling Agreement (this &quot;<u>Agreement</u>
                    &quot;) is entered into by{" "}
                    {!contractdata ? (
                      <input
                        onChange={(e) => setInput1(e.target.value)}
                        className="forminput"
                        type="text"
                      />
                    ) : input1 ? (
                      input1
                    ) : (
                      <input className="forminput" type="text" />
                    )}
                    ("<u>Owner</u>"), as the owner of the home or buisness
                    located at{" "}
                    {!contractdata ? (
                      <input
                        onChange={(e) => setInput2(e.target.value)}
                        className="forminput"
                        type="text"
                      />
                    ) : input2 ? (
                      input2
                    ) : (
                      <input className="forminput" type="text" />
                    )}
                    (the"<u>Property</u>"), and Impeccable
                    Installations,Inc.d/b/a XCED Design Build ("
                    <u>Contractor</u>"). The signature or approval of any Owner
                    of home or buisness, or its agent, shall bind all Owners for
                    all purposes, and all Owners shall be jointly and severally
                    responsible for all obligations under this Agreement and any
                    change orders.
                  </p>

                  <p className={classes.para1}>
                    1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Project Description.</u>&nbsp;&nbsp;&nbsp; Contractor
                    shall furnish all materials and labor for the proposed
                    alterations and imporvements at the Property per the
                    attached Proposal.
                  </p>

                  <p className={classes.para2}>
                    2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Anticipated Work Timeline.</u>&nbsp;&nbsp;&nbsp;&nbsp;
                    Provided all selections are made in timely fashion, all
                    necessary materials are available, a building permite can be
                    obtained and the Owner has compiled in full with the terms
                    of this Agreement, the estimated start date is{" "}
                    {!contractdata ? (
                      <input
                        onChange={(e) => setInput3(e.target.value)}
                        className="forminput"
                        type="text"
                      />
                    ) : input3 ? (
                      input3
                    ) : (
                      <input className="forminput" type="text" />
                    )}
                    . The estimated completion date is
                    {!contractdata ? (
                      <input
                        onChange={(e) => setInput4(e.target.value)}
                        className="forminput"
                        type="text"
                      />
                    ) : input14 ? (
                      input14
                    ) : (
                      <input className="forminput" type="text" />
                    )}
                    . Owner acknowledges that these dates are estimates only and
                    the Contractor shall not be responsible for delays caused by
                    factors unknown or undisclosed to Contractor or any factors
                    outside Contractor reasonable control, including without
                    limitation: delays caused by Owner, delays caused by
                    subcontractors, delays caused by material shortages or
                    supply chain issues,acts of God, weather, strikes, or other
                    labor union activities, extra work or changes requested by
                    Owner, civil commotion, materials shortages, failure of
                    Owner to make payment when due, or delays caused by
                    inspections, or changes ordered by inspectors.
                  </p>

                  <p className={classes.para3}>
                    3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Project Cost and Payments.</u>&nbsp;&nbsp;&nbsp; Owner
                    agrees to pay Contractor{" "}
                    {!contractdata ? (
                      <input
                        onChange={(e) => setInput5(e.target.value)}
                        className="forminput"
                        type="text"
                      />
                    ) : input5 ? (
                      input5
                    ) : (
                      <input className="forminput" type="text" />
                    )}{" "}
                    (${" "}
                    {!contractdata ? (
                      <input
                        onChange={(e) => setInput6(e.target.value)}
                        className="forminput"
                        type="text"
                      />
                    ) : input6 ? (
                      input6
                    ) : (
                      <input className="forminput" type="text" />
                    )}
                    ) to Contractor for the work to be performed under this
                    Agreement, subject to increase for building material costs
                    as provided below, payable as follows:
                  </p>

                  <p className={classes.para4}>
                    a.&nbsp;&nbsp;&nbsp; ${" "}
                    {!contractdata ? (
                      <input
                        onChange={(e) => setInput7(e.target.value)}
                        className="forminput"
                        type="text"
                      />
                    ) : input7 ? (
                      input7
                    ) : (
                      <input className="forminput" type="text" />
                    )}{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp; Due prior to the commencement of
                    work.
                  </p>
                  <p className={classes.para5}>
                    b.&nbsp;&nbsp;&nbsp; ${" "}
                    {!contractdata ? (
                      <input
                        onChange={(e) => setInput8(e.target.value)}
                        className="forminput"
                        type="text"
                      />
                    ) : input8 ? (
                      input8
                    ) : (
                      <input className="forminput" type="text" />
                    )}{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp; Weekly installment payments,
                    starting{" "}
                    {!contractdata ? (
                      <input
                        onChange={(e) => setInput9(e.target.value)}
                        className="forminput"
                        type="text"
                      />
                    ) : input9 ? (
                      input9
                    ) : (
                      <input className="forminput" type="text" />
                    )}
                    , if applicable.
                  </p>
                  <p className={classes.para6}>
                    c.&nbsp;&nbsp;&nbsp; ${" "}
                    {!contractdata ? (
                      <input
                        onChange={(e) => setInput10(e.target.value)}
                        className="forminput"
                        type="text"
                      />
                    ) : input10 ? (
                      input10
                    ) : (
                      <input className="forminput" type="text" />
                    )}{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp; Due at substaintial completion.
                    Upon final payment of all amounts due Contractor shall
                    deliver a release of all liens to Owner.
                  </p>

                  <p className={classes.para7}>
                    The project is "substainly complete" when all items
                    specified in the Proposal have been constructed or
                    installed. Upon notice of substaintial completion, Owner
                    shall review the work and prepare a punch list that
                    identifies any item which Owner claims are incomplete or
                    deficient. "Punch List" items requiring adjustment shall not
                    be cause for delay of final payment, but rather shall be
                    considered warranty items.
                  </p>

                  <p className={classes.para8}>
                    Owner understands that the contract price for this project
                    has been calculated based on current prices for building
                    materials as of the date of the attached Proposal. However,
                    due to various market forces including the Covid-19
                    pandemic, the market for building materials is volatile, and
                    sudden price increases and material shortages could occur
                    that are outside Contractor's control. In the event of an
                    increase in the price of building materials purchased after
                    the date of the Proposal, Owner agress to pay that cost
                    increase to Contractor. Contractor shall notify owner with
                    the description of the building materials and the increased
                    cost, supported by invoices or other documentation.
                  </p>

                  <p className={classes.para9}>
                    4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Termination.</u>&nbsp;&nbsp;&nbsp; Under the Illinois
                    law, for the residential projects Owner may have the right
                    to cancel this agreement within 3 buisness days after
                    signing(see attached Owner Acknowledgement and Notice of
                    Cancellation). If the 3-day right to cancel does not apply.
                    and Owner terminates this Agreement for any reason before
                    Contractor has begun work, Owner will be responsible to pay
                    Contractor all expenses incurred and non-cancellable
                    obligations, and Contractor shall be entitled to 10% of the
                    total project cost as liquidated damages. If Owner
                    terminates this Agreement for any reason after Contractor
                    has begun work, Owner will be responsible to pay Contractor
                    for all completed work, all expenses incurred and
                    non-cancellable obligations, plus reasonable overhead and
                    profit of not less than 20% on the work not completed.
                  </p>

                  <p className={classes.para10}>
                    5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<u>Changes.</u>
                    &nbsp;&nbsp;&nbsp; Except for cost increases described in
                    Section 3, no changes from the original Proposal shall be
                    made unless Contractor and Owner agree in writing as the
                    nature and extent of such changes. Any agreed changes to the
                    project scope of work, cost or timing shall be pursuant to a
                    written change order, which shall be satisfied by email
                    correspondence in which a proposed change order is proposed
                    by Contractor and approved by Owner. Although Contractor
                    shall have the right to refuse any proposed changes or
                    alterations after construction has started, Contractor shall
                    not do unreasonably. Owner acknowledges that any changes may
                    add additional time and costs the project. Owner agrees that
                    any additional work and orders for materials, appliances,
                    equipment and other items that are not included in the
                    original Proposal must be paid for in full, wihtin 7 days
                    from the date requested by Owner, even if we have not yet
                    recieved the items from the supplier, and such payment is in
                    addition to the amounts due according to the payment
                    schedule in Section 3. Owner also understands that special
                    order items which may include, but are not limited to,
                    special materials, custom windows, doors and cabinets,
                    cannot be returned or refunds are not available.
                  </p>

                  <p className={classes.para11}>
                    6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Contractor's Obligations.</u>&nbsp;&nbsp;&nbsp;
                    Contractor shall perform its work in a good and workman like
                    manner, and in accordance with applicable building codes and
                    laws. Contractor shall keep work site in a reasonably clean
                    and orderly fashion in light of the work, and remove dispose
                    of all construction debris upon completion of the work and
                    leave the project in broom clean condition.
                  </p>

                  <p className={classes.para12}>
                    7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Late Payment.</u>&nbsp;&nbsp;&nbsp; If payments are not
                    made on time, Contractor may suspend work untill full
                    payment is made. In addition, because Owner's failure to
                    make prompt payments will cause a financial hardship on
                    Contractor, Owner agrees to pay a finance charge at the rate
                    of one and one-half percent(1.5%) per month on the late
                    balance from the due date. Owner will also responsible for
                    Contractor's costs of collection, including reasonable
                    attorney's fees and court costs, regardless of whether
                    litigation is initiated, and lien fees and costs.
                  </p>

                  <p className={classes.para13}>
                    8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Permits and othe Costs.</u>&nbsp;&nbsp;&nbsp; Owner shall
                    obtain and pay for all permits and other approvals (e.g.,
                    zoning approvals) necessary for the work. Unless
                    specifically included in the Proposal, no design fees,
                    permit fees, building management fees, utility charges,
                    water shutdown charges, parking charges, acoustical testing
                    charges, code corrections, fire suppression relocation are
                    included, and all such items shall be subject to a change
                    order and at Owner's expense.
                  </p>

                  <p className={classes.para14}>
                    9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Certain Exclusions and Limitations.</u>
                    &nbsp;&nbsp;&nbsp;&nbsp; Owner acknowledges that this
                    agreement and the Proposal is based upon the Contractor's
                    observation of readily apparent conditions. Conditions such
                    as damage caused by termites or other insects, moisture,
                    mold, dry rot or decay, hidden code violations, necessary
                    rerouting of vents, pipes, ducts or wiring, or other
                    concealed conditions, may require extra labor or materials,
                    which are not part of this contract. If such conditions are
                    discovered, Contractor will notify Owner, and the parties
                    shall discuss a change order that addresses to those issues.
                    Contractor is not responsible for testing or removal of any
                    hazardous materials, such as mold, lead or asbestos, and any
                    such work may need to be performed by a third party and at
                    additional cost to Owner. Owner acknowledges the limitations
                    of matching building materials. While contractor shall make
                    reasonable efforts to match new and existing materials where
                    appropraite, exact matching is not promised, and Contractor
                    may substitute materials of smiliar quality and design.
                  </p>

                  <p className={classes.para15}>
                    10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Removal and Damage of Existing Items.</u>
                    &nbsp;&nbsp;&nbsp; Contractor will take reasonable care to
                    remove existing items, such as glass, mirrors, hard
                    surfaces, countertops, sinks and cabinetry, for later
                    reinstallation as desired by Owner. However, due to the
                    nature of such items, even with reasonable care, breakage or
                    other damage may occur as part of the removal or
                    reinstallation process. Contractor is not responsible for
                    such damage, the cost to repair or replace such items, or
                    for any resulting delay in the project. All costs to replace
                    or repair such items are the sole responsibility of Owner,
                    and Owner is responsible for to fulfill the balance due to
                    Contractor without offset or deduction for such items.
                  </p>

                  <p className={classes.para16}>
                    11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Pets and Children.</u>&nbsp;&nbsp;&nbsp; It is the
                    responsibility of Owner to keep children and pets safe and
                    out of the work area while construction is underway. Owner
                    agrees that Contractor will not be held responsible for
                    child's or pet's safety.
                  </p>

                  <p className={classes.para17}>
                    12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Warranty.</u>
                    &nbsp;&nbsp;&nbsp; Provided thar Owner has compiled with all
                    items of this Agreement, and expressly conditioned on Owner
                    being current with all payments due under this Agreement,
                    Contractor warrants its workmanship against substaintial
                    defects for one (1)year from the date of substaintial
                    completion of the work. Contractor shall provide Owner with
                    all manufacturer and supplier warranties recieved by
                    Contractor on material and appliances installed by
                    Contractor, and Owner is limited to the manufacturer's
                    warranties for defects in materials and appliances. If Owner
                    has any complaints or other potential issues with
                    Contractor's work, Owner shall provide Contractor the
                    opportunity to review and evaluate any claims, and
                    Contractor shall have the opportunity to correct or finish
                    any work prior to Owner engaging another contractor to
                    evaluate or perform any corrective or incomplete work. If
                    Owner does not allow Contractor the opportunity to correct
                    or finish any work, Owner remains responsible for full
                    payment of all amounts due to Contractor, and waives any
                    right, to deduct or offset the amount paid to another
                    contractor from the amount due to Contractor, or seek
                    reimbursement from Contractor for the amount paid to another
                    contractor or payment for damages relating to inconvenience
                    or delay. All warranty work shall be done by Contractor.
                    Contractor shall not be responsible for the costs of any
                    work performed by a third party, unless owner notifies
                    Contractor of a potential warranty claim and Contractor
                    fails to respond to such notice within a reasonable time.
                    Contractor does not provide any warrant related to work
                    performed by Contractor or any manufacturer's warranty for
                    materials or appliances not supplied by Contractor.
                  </p>

                  <p className={classes.para18}>
                    13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Insurance.</u>
                    &nbsp;&nbsp;&nbsp; Contractor shall carry all required
                    workers compensation insurance for its employees and shall
                    maintain public liability insurance and builders risk
                    policies to cover the project. Once imporvements or
                    alterations are completed or installed, their risk
                    protection shall transfer to the Owner's homeowner's
                    insurance coverage. It is Owner's responsibility to contact
                    their homeowner's insurance carrier as necessary for any
                    increased coverage or limits, even if Contractor is still
                    working on the location of the project.
                  </p>

                  <p className={classes.para19}>
                    14.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Subcontractors.</u>&nbsp;&nbsp;&nbsp; Contractor may use
                    subcontractors on the project. The law requires that
                    Contractor shall submit a sworn statement of persons
                    furnishing labor, services, materials, fixtures, apparatus
                    or machinery, forms or form work, and the amounts due or to
                    become due to each, before any payments are required to be
                    made to the Contractor.
                  </p>

                  <p className={classes.para20}>
                    15.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Amendments and Waiver.</u>&nbsp;&nbsp;&nbsp; No
                    amendment, waiver or consent with respect to any provision
                    of this Agreement shall be effective unless in writing and
                    signed by the Parties.
                  </p>

                  <p className={classes.para21}>
                    16.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Entire Agreement.</u>&nbsp;&nbsp;&nbsp; This Agreement,
                    including the Proposal, constitutes the entire agreement
                    between the parties and incorporates all prior and
                    contemporaneous discussions and negotiations.
                  </p>

                  <p className={classes.para22}>
                    17.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Dispute Resolution.</u>&nbsp;&nbsp;&nbsp; This agreement
                    shall he governed by the laws of the State of Illinois.
                    Exclusive jurisdiction for any dsipute relating to this
                    Agreement or Contractor's work shall be in the state or
                    federal courts in Cook County, Illinois, even if Owner
                    resides in a different place, and the parties expressly
                    agree to the personal jurisdiction of such courts. The
                    prevailing party in any litigation shall have the right to
                    recover its costs, including reasonable attorney's fees.
                    OWNER IRREVOCABLY WAIVES, TO THE FULLEST EXTENT PERMITTED BY
                    APPLICABLE LAW, AND ALL RIGHT TO TRIAL BY JURY IN ANY LEGAL
                    PROCEEDING ARISIG OUT OF OR RELATING TO THIS AGREEMENT OR
                    CONTRACTOR'S WORK.
                  </p>

                  <p className={classes.para23}>
                    If this is residental project, Owner acknowledges that,
                    prior to signing this Agreement, Contractor advised Owner as
                    to the presence of the above which requires Owner to waive
                    the right to a trial by jury. Owner has the option of
                    accepting or rejecting this provision. Please sign your name
                    and write "accept" or "reject" below.
                  </p>
                  <div className={classes.acceptRejectBox}>
                    <p className={classes.signaturePara}>
                      Sign:{" "}
                      <SignatureCanvas
                        penColor="black"
                        ref={signatureRef1}
                        canvasProps={{
                          width: 200,
                          height: 80,
                          className: "sigCanvas1",
                        }}
                      />
                      <button
                        className={classes.clearbtn}
                        onClick={handleClear1}
                      >
                        Clear
                      </button>
                    </p>
                    <p className={classes.para25}>
                      {" "}
                      {!contractdata ? (
                        <input
                          onChange={(e) => setInput12(e.target.value)}
                          className="forminput"
                          type="text"
                        />
                      ) : input12 ? (
                        input12
                      ) : (
                        <input className="forminput" type="text" />
                      )}
                      <br />
                      Accept/Reject
                    </p>
                  </div>

                  <p className={classes.para26}>
                    18.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <u>Counterparts.</u>&nbsp;&nbsp;&nbsp; This Agreement may be
                    executed in two or more counterparts, each of which shall be
                    deemed an original, but all of which together shall
                    constitute one and the same instrument. Signatrues delivered
                    by fax or email transmission in of .pdf similar format shall
                    be deemed to original signatures for all purposes.
                  </p>

                  <p className={classes.para27}>
                    Signature page to XCED Design Build Remodeling Contract
                  </p>

                  <p className={classes.para28}>OWNER(or its agent)</p>
                  <p className={classes.signaturePara}>
                    Sign:{" "}
                    <SignatureCanvas
                      ref={signatureRef2}
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 80,
                        className: "sigCanvas1",
                      }}
                    />
                    <button className={classes.clearbtn} onClick={handleClear2}>
                      Clear
                    </button>
                  </p>
                  <p>
                    Print:{" "}
                    {!contractdata ? (
                      <input
                        onChange={(e) => setInput14(e.target.value)}
                        className="forminput"
                        type="text"
                      />
                    ) : input14 ? (
                      input14
                    ) : (
                      <input className="forminput" type="text" />
                    )}
                  </p>
                  <p className={classes.signaturePara}>
                    Sign:{" "}
                    <SignatureCanvas
                      ref={signatureRef3}
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 80,
                        className: "sigCanvas1",
                      }}
                    />
                    <button className={classes.clearbtn} onClick={handleClear3}>
                      Clear
                    </button>
                  </p>
                  <p>
                    Print:{" "}
                    {!contractdata ? (
                      <input
                        onChange={(e) => setInput16(e.target.value)}
                        className="forminput"
                        type="text"
                      />
                    ) : input16 ? (
                      input16
                    ) : (
                      <input className="forminput" type="text" />
                    )}
                  </p>
                  <p>
                    Date:{" "}
                    {!contractdata ? (
                      <input
                        onChange={(e) => setInput17(e.target.value)}
                        className="forminput"
                        type="text"
                      />
                    ) : input17 ? (
                      input17
                    ) : (
                      <input className="forminput" type="text" />
                    )}
                  </p>
                  <div className="implementcss">
                    <p>
                      IMPECCABLE INSTALLATIONS,INC.
                      <br />
                      d/b/a XCED DESIGN BUILD ("Contract")
                    </p>
                    <p>
                      {" "}
                      {!contractdata ? (
                        <input
                          onChange={(e) => setInput18(e.target.value)}
                          className="forminput"
                          type="text"
                        />
                      ) : input18 ? (
                        input18
                      ) : (
                        <input className="forminput" type="text" />
                      )}
                      <br />
                      By: Gary Dayan, President
                    </p>
                  </div>
                  <h2 className={classes.heading}>
                    <u>
                      OWNER ACKNOWLEDGEMENT - KNOW YOUR CONSUMER RIGHTS BROCHURE
                    </u>
                  </h2>

                  <p className={classes.para31}>
                    Owner acknowledges that it has recieved the borchure called:
                    "Home Repair: Know Your Consumer Rights" prior to the
                    commencement of any work on this project:
                  </p>

                  <p className={classes.signaturePara}>
                    Owner Signatrue:{" "}
                    <SignatureCanvas
                      ref={signatureRef4}
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 80,
                        className: "sigCanvas1",
                      }}
                    />
                    <button className={classes.clearbtn} onClick={handleClear4}>
                      Clear
                    </button>
                  </p>

                  <h2 className={classes.heading}>
                    <u>CANCELLATION</u>
                  </h2>

                  <p className={classes.para33}>
                    UNDER ILLONIS LAW, YOU HAVE A THREE DAY RIHT TO CANCEL THIS
                    AGREEMENT, IF THIS AGREEMENT IS SIGNED WHEN CONTRACTOR'S
                    REPRESENTATIVE IS PRESENT IN YOUR HOME, SUBJECT TO CERTAIN
                    EXCLUSIONS.
                  </p>

                  <p className={classes.para34}>
                    IF APPLICABLE, YOU, THE CONSUMER, MAY CANCEL THIS
                    TRANSACTION AT ANY TIME PRIOR TO MIDNIGHT OF THE THIRD
                    BUISNESS DAY AFTER THE DATE OF THIS TRANSACTION. SEE THE
                    ATTACHED NOTICE OF CANCELLATION FORM FOR AN NOTICE OF
                    CANCELLATION
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
}
