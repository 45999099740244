import {
  drawerWidth,
  transition,
  container,
  margintopval,
} from "assets/jss/material-dashboard-react.js";


console.log({drawerWidth});
const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth.drawerwidth}px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
  },
  content: {
    marginTop: `${margintopval.margintop}px`,
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)",
  },
  container,
  map: {
    marginTop: "70px",
  },
  sidebarui: {
    background: "#0F172A",
  },
});

export default appStyle;
