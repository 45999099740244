import React,{useCallback} from "react";
// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// @material-ui/core
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
// import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Accessibility from "@material-ui/icons/Accessibility";
// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
// core components
// import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
// import Tasks from "components/Tasks/Tasks.js";
// import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import Danger from "components/Typography/Danger.js";
// import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
// import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";
import TextField from "@material-ui/core/TextField";
import Snackbar from '@material-ui/core/Snackbar';

// import Button from "@material-ui/core/Button";
// import Link from "@material-ui/core/Link";
// import MenuItem from "@material-ui/core/MenuItem";
// import { bugs, website, server } from "variables/general.js";
// import notiicon from "assets/NotificationIcon.png";
// import {
//   dailySalesChart,
//   emailsSubscriptionChart,
//   completedTasksChart,
// } from "variables/charts.js";
import logo from "assets/Xced Logo 1.png";
import { useHistory } from "react-router-dom";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Login() {
  const url = "https://bathroombackend.instantsolutionslab.site/api/";
  // const url = "https://api.intrasaur.kabfood.com/api/";
  const classes = useStyles();
  const [email, setEmail] = React.useState('');
  const [pass, setPass] = React.useState('');
  const [message, setMessage] = React.useState('');
  const history = useHistory();
  // const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
 


  const login = () =>{

     
    // const data = new FormData();
    // data.append("first_name", 'Arsalan');
    // data.append("last_name", "Amir");
    // data.append("email", "faizan@purelogics.net");
    // data.append("password", "saimshani@");
    // data.append("c_password", "saimshani@");
    // // data.append("country", "Pakistan");
    // // data.append("website", "www.pl.com");
    // data.append("role", "user");

    // fetch(url+'apiregister',{
    //   method: 'POST',
    //   body: data, 
    // })
    // .then(res => res.json())
    // .then((data) => {
    //     console.log(data);
    // })
    // .catch(console.log);
  
   if(email != "" && pass != "" && email != undefined && pass != undefined &&
   email != null && pass != null){ 
    const data = new FormData();
    data.append("email", email);
    data.append("password", pass);
    fetch(url+'login',{
      method: 'POST',
      body: data,
    })
    .then(res => res.json())
    .then((data) => {
        console.log(data);  
      if(data.status == 200){
        setMessage("Login Successfully!");
        handleClick();
        console.log(data);
        localStorage.setItem('logininfo', JSON.stringify(data));
        localStorage.setItem('login', true);
        let myInterval = setInterval(() => {
          // history.push('/admin/dashboard');
          window.open("/admin/dashboard","_self");  
        }, 1000)
      }else{
        // alert(data.email[0]);
        setMessage(data.email[0]);
        handleClick();
      }  
    })
    .catch(console.log);
   }else{
    // alert("Please fill all fields");
    setMessage("Please fill all fields");
    handleClick();  }
  }
  return (
    <div className="main-color-login colordivlogin" id="main-div-login">
      <div className="container">
        <div className="screen">
          <div className="screen__content">
            <img className={classes.loginimage} src={logo} width={154}/>
            <div className="login">
              <div className="login__field">
                {/* <i className="login__icon fas fa-user"></i> */}
                <input value={email} onChange={(e)=> setEmail(e.target.value)} type="text" className="login__input" placeholder="Email"></input>
              </div>
              <div className="login__field">
                {/* <i className="login__icon fas fa-lock"></i> */}
                <input value={pass} onChange={(e)=> setPass(e.target.value)} type="password" className="login__input" placeholder="Password"></input>
              </div>

              <button onClick={login} className="button login__submit">
                <span className="button__text">Login</span>
              </button>				
            </div>
          </div>
          <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4"></span>
            <span className="screen__background__shape screen__background__shape3"></span>		
            <span className="screen__background__shape screen__background__shape2"></span>
            <span className="screen__background__shape screen__background__shape1"></span>
          </div>		
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={900}
        onClose={handleClose}
        message={message}
      />
      <GridContainer></GridContainer>
    </div>
  );
}
