import { makeStyles } from "@material-ui/core/styles";
import Grid from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import notiicon from "assets/NotificationIcon.png";
import Generate from "assets/Generate.png";
import Email from "assets/Email.png";
import Group8 from "assets/Group 8.png";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import morepic from "assets/Group 1.png";
import IconButton from "@material-ui/core/IconButton";


const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  loader: {
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#333",
  },
  sendbtn: {
    position: "relative",
    padding: "13px",
    width: "110px",
    marginRight: "10px",
    color: "#fff",
    backgroundColor: "#ff9800",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  spanuserdetail: {
    marginLeft: "24px",
    fontWeight: "400",
  },
  userdetailtit: {
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "18px",
  },
  textalignbtn: {
    textAlign: "center",
    width: "100%",
  },
  registertextfield: {
    margin: "0 auto",
    display: "block",
    padding: "10px",
    marginBottom: "19px",
    width: "75%",
  },
  cancelbtn: {
    position: "relative",
    padding: "13px",
    width: "110px",
    marginRight: "10px",
    color: "#fff",
    backgroundColor: "#cdd1d4",
    border: "none",
    borderRadius: "5px",
  },

  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  headertitle: {
    marginBottom: "0",
    marginLeft: "40px",
    color: "#000",
    fontWeight: "400",
  },
  notificationtitle: {
    marginTop: "0",
    fontSize: "12px",
    marginLeft: "42px",
    color: "#000",
    fontWeight: "400",
  },
  gridcontainer: {
    backgroundColor: "#eeeeee",
    height: "498px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardui: {
    textAlign: "center",
    margin: "none",
  },
  cardheader: {
    fontSize: "70px",
    marginBottom: "0",
    fontWeight: "900",
    color: "#F8A11C",
  },
  cardheadermedile: {
    fontSize: "70px",
    marginBottom: "0",
    fontWeight: "900",
    color: "#000",
  },
  carddesc: {
    marginTop: "0",
    marginBottom: "30px",
  },
  reporttag: {
    backgroundColor: "#eee",
    color: "#000",
    marginBottom: "0",
    boxShadow: "unset",
    width: "200px",
    marginLeft: "75px",
    marginTop: "65px",
    textAlign: "center",
    padding: "10px",
  },
  selectui: {
    position: "relative",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "36px",
    height: "30px",
    bottom: "-65px",
    width: "200px",
    textAlign: "center",
    border: "solid 2px #CDD1D4",
    padding: "20px",
  },
  "@media(max-width:570px) and (min-width:450px)": {
    reporttag: {
      width: "150px",
      marginLeft: "50px",
    },
    selectui: {
      width: "160px",
    },
  },
  "@media(max-width:449px)": {
    reporttag: {
      width: "140px",
      marginLeft: "0px",
    },
    selectui: {
      width: "160px",
      marginRight: "0px",
    },
  },

  notiicon: {
    width: "10px",
    marginRight: "5px",
  },
  customers: {
    fontFamily: "Arial, Helvetica, sans-serif",
    borderCollapse: "collapse",
    width: "100%",
  },
  customerstd: {
    border: "1px solid #ddd",
    padding: "8px",
  },
  tableUpgradeWrapper: {
    display: "block",
    width: "100%",
    // overflowX: "auto",
    backgroundColor: "#eee",
    WebkitOverflowScrolling: "touch",
    MsOverflowStyle: "-ms-autohiding-scrollbar",
  },
  table: {
    width: "100%",
    maxWidth: "90% !important",
    marginLeft: "5% !important",
    backgroundColor: "white",
    borderCollapse: "collapse",
    display: "table",
    borderSpacing: "2px",
    borderColor: "grey",
    marginTop: "6%",
    marginBottom: "6%",
    "& thdead tr th": {
      fontSize: "1.063rem",
      padding: "12px 8px",
      verticalAlign: "middle",
      fontWeight: "300",
      borderTopWidth: "0",
      borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
      textAlign: "inherit",
      whiteSpace: "nowrap",
    },
    "& tbody tr td": {
      padding: "12px 8px",
      verticalAlign: "middle",
      borderTop: "1px solid rgba(0, 0, 0, 0.06)",
      whiteSpace: "nowrap",
    },
    "& td, & th": {
      display: "table-cell",
      whiteSpace: "nowrap",
    },
  },
  center: {
    textAlign: "center",
  },
  colortd: {
    backgroundColor: "#CDD1D4",
    textAlign: "center",
    padding: "10px",
  },
  imgwidth: {
    width: "155px",
    marginRight: "5px",
  },
  more: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
  dropdownimg: {
    width: "46px",
  },
  textright: {
    textAlign: "right",
  },
  divright: {
    // textAlign: "right",
    marginBottom: "50px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  diplayblock: {
    display: "block",
  },
  displayinlineblock: {
    display: "inline-block",
    margin: "10px",
    width: "-webkit-fill-available",
  },
  marginstyle: {
    margin: "0",
    fontWeight: "bold",
    color: "#5A6670",
  },
  statestylelabel: {
    marginLeft: "10px",
    display: "inline-block",
    marginRight: "35px",
  },
  statestylelabel1: {
    display: "inline-block",
  },
  inputstyle: {
    width: "-webkit-fill-available",
    padding: "10px",
    border: "solid 1px #CDD1D4",
    color: "#5A6670",
  },
  textareastyle: {
    width: "90%",
    marginLeft: "10px",
    border: "solid 1px #CDD1D4",
    color: "#5A6670",
  },
  textareamargin: {
    marginLeft: "10px",
    margin: "0",
    fontWeight: "bold",
  },
  statestyle: {
    width: "145px",
    padding: "10px",
    fontSize: "14px",
    border: "solid 1px #CDD1D4",
    color: "#5A6670",
  },
  zipcodestyle: {
    width: "110px",
    padding: "9px",
    border: "solid 1px #CDD1D4",
    color: "#5A6670",
  },
  btnupdate: {
    backgroundColor: "#F8A11C",
    marginRight: "60px",
    marginTop: "5%",
    border: "none",
    padding: "10px",
    width: "120px",
    color: "#fff",
    borderRadius: "5px",
    marginBottom: "20px",
    cursor: "pointer",
  },
  fieldsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  fieldWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  smallFieldBox: {
    display: "flex",
    flexDirection: "column",
  },
  "@media(max-width:550px)": {
    fieldWrapper: {
      flexDirection: "column",
    },
    inputstyle: {
      width: "-webkit-fill-available",
    },
    displayinlineblock: {
      width: "-webkit-fill-available",
    },
    divright: {
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    table: {
      maxWidth: "100% !important",
      marginLeft: "0% !important",
    },
    userdetailtit: {
      textAlign: "left",
      fontWeight: "bold",
      fontSize: "18px",
    },
    spanuserdetail: {
      marginLeft: "24px",
      fontWeight: "400",
    },
  },
};

const useStyles = makeStyles(styles);
// const ITEM_HEIGHT = 48;
const ITEM_HEIGHT = 48;
const options = [
  "Edit Configuration",
  "Edit Price",
  "View Contract Form",
  "Customer Detail",
];

// const ITEM_HEIGHT = 48;
export default function TableList() {
  const classes = useStyles();
  const [show, setShow] = useState(true);
  const [age, setAge] = useState(0);
  const [userinfo, setUserinfo] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [openalert, setOpenalert] = useState(false);
  const [openassignval, setOpenassignval] = useState(false);
  const [openpopup, setOpenpopup] = useState(false);
  const [openpopupdetail, setOpenpopupdetail] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [discount, setDiscount] = useState("");
  const [customerdetail, setCustomerdetail] = useState([]);
  const [appoinmentid, setAppoinmentid] = useState("");
  const [selectsales, setSelectsales] = useState("");
  const [allusers, setAllusers] = useState([]);
  const [discountid, setDiscountid] = useState("");
  const [price, setEditprice] = useState("");
  const [userdetail, setUserdetail] = useState({});
  const handleDiscountChange = (e) => {
    const discountValue = e.target.value;
    setDiscount(discountValue);
  };
  const usertoken = JSON.parse(localStorage.getItem("logininfo"))?.token;
  const setdatacustomer = (id) => {
    const selectedCustomer = data.find((customer) => customer.id === id);
    if (selectedCustomer) {
      console.log(selectedCustomer)
      setCustomerdetail([selectedCustomer]); // Set the selected customer details
      console.log("Selected customer ID:", id);
    } else {
      console.error("Customer not found");
    }
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("logininfo"));
    setUserinfo(userInfo);
    getAssignedSales();
  }, []);

  const getAssignedSales = () => {
    setLoading(true);
    fetch("http://127.0.0.1:8000/api/customers", {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${usertoken}`,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setData(data.length ? data : []);
          setMessage(data.length ? "" : "No sales data available.");
        } else {
          setMessage(data.message || "Error fetching data.");
          setData([]);
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        setMessage("Error fetching data.");
        setData([]);
      })
      .finally(() => setLoading(false));
  };

  const searchalldata = (data) => {
    fetch(
      `https://bathroombackend.instantsolutionslab.site/api/get-all-appintments?search=${data}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${usertoken}`,
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => setData(data.data.data))
      .catch(console.log);
  };
  const handleClickevent = (option, customerData) => {
    console.log("Option selected:", option);
    console.log("Customer Data:", customerData);

    if (option === "Edit Price") {
      if (customerData) {

        setEditprice(customerdetail?.[0]?.price || 0);
        setDiscount(customerdetail?.[0]?.discount || "");
        setDiscountid(customerdetail?.[0]?.id || "");


        handleClickOpen();
      } else {
        console.error("Invalid customer data");
      }
    } else if (option === "View Contract Form") {
      localStorage.setItem("userappointmentid", customerData.id);
      window.open("/admin/contractform", "_self");
    } else if (option === "Customer Detail") {
      showuserdetail();
    }
  };



  const handleClickOpen = () => {
    setOpenpopup(true);
  };

  const handleClickOpendetail = () => {
    setOpenpopupdetail(true);
  };

  const handleClosepoup = () => {
    setOpenpopup(false);
  };

  const handleClosepoupdetail = () => {
    setOpenpopupdetail(false);
  };

  const handleCloseassign = () => {
    setOpenassignval(false);
  };
  const showuserdetail = () => {
    setUserdetail();
    handleClickOpendetail();
  };

  const savediscount = () => {
    if (discount !== "" && discount !== null && discount !== undefined) {
      const parsedPrice = parseFloat(price);
      const parsedDiscount = parseFloat(discount);
  
      if (isNaN(parsedPrice) || parsedPrice <= 0) {
        setMessage("Invalid price value");
        return;
      }
  
      if (isNaN(parsedDiscount) || parsedDiscount < 0) {
        setMessage("Invalid discount value");
        return;
      }
  
     
      const finalPrice = parsedPrice - parsedDiscount > 0 ? parsedPrice - parsedDiscount : 0;
  
      const payload = {
        name: customerdetail[0]?.name,
        email: customerdetail[0]?.email,
        contact_number: customerdetail[0]?.contact_number,
        price: finalPrice,
      };
  
      fetch(`http://127.0.0.1:8000/api/customers/${discountid}`, {
        method: "PUT",
        headers: new Headers({
          Authorization: "Bearer " + usertoken,
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.message === "Customer updated successfully") {
            
            setDiscount("");
            setEditprice("");
  
           
            if (data.bathroom_user_updated) {
              getAssignedSales();
            }
  
            handleClosepoup();
  
            setMessage("Customer details updated successfully.");
          } else {
            setMessage(data?.message || "Error updating customer.");
          }
        })
        .catch((err) => {
          console.error("Error updating customer:", err);
          setMessage("Error updating customer.");
        });
    } else {
      setMessage("Please fill in the discount field.");
    }
  };
  
  


  const getallappointments = () => {
    fetch(
      "https://bathroombackend.instantsolutionslab.site/api/get-all-appintments",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${usertoken}`,
          Accept: "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setData(data.data.data))
      .catch(console.log);
  };

  const searchfun = (data) => {
    if (data.length > 3) {
      searchalldata(data);
    } else {
      getallappointments();
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const Assignrole = () => {
    const payload = { user_id: selectsales, appointment_id: appoinmentid };
    fetch("https://bathroombackend.instantsolutionslab.site/api/assign-sales", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${usertoken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then(() => handleCloseassign())
      .catch(console.log);
  };

  if (loading) {
    return <div style={styles.overlay}>
    <div style={styles.loader}>
      <span>Loading...</span>
    </div>
  </div>;
  }
  return (
    <div>
      
      <h3 className={classes.headertitle}>
        Welcome back {userinfo.user?.name}!
      </h3>
      <p className={classes.notificationtitle}>
        <img className={classes.notiicon} src={notiicon} />
        You have 2 appointments this week.
      </p>
      <GridContainer>
        <div className={classes.reporttag}>New Appointments</div>
        <Select
          className={classes.selectui}
          labelId="demo-select-small"
          id="demo-select-small"
          label="Age"
          value={age}
        // onChange={handleChange}
        >
          <MenuItem value={0}>Filter by date</MenuItem>
          <MenuItem value={10}>This Week</MenuItem>
          <MenuItem value={20}>30 Days</MenuItem>
          <MenuItem value={30}>This Year</MenuItem>
        </Select>
        <div className={classes.tableUpgradeWrapper}>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th className={classes.colortd}>Name</th>
                  <th className={classes.colortd}></th>
                  <th className={classes.colortd}>Email</th>
                  <th className={classes.colortd}>Price</th>
                  <th className={classes.colortd}>Contact Number</th>
                  <th className={classes.colortd}></th>
             
                  
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      {/* <td className={classes.center}>{item.name}</td> */}
                      <td style={{ width: "100px" }}>{item.name}</td>
                      <td>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(event) => {
                              setdatacustomer(item.id); // Yaha item.id ko pass karein
                              handleClick(event); // Ye function open state handle karega
                            }}
                          >
                            <img className={classes.more} src={morepic} alt="More" />
                          </IconButton>
                          {/* <div className={classes.moreicon} onClick={(e) => handleClick(e)}>
                          <img src={morepic} alt="more" />
                        </div> */}
                        </div>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}

                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: "20ch",
                            },
                          }}
                        >
                          {options.map((option, index) => (
                            <MenuItem
                              key={index}
                              onClick={() => {
                                console.log("Passing customer data:", data);
                                handleClickevent(option, item);
                                handleClose();
                              }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </td>
                      <td className={classes.center}>{item.email}</td>
                      <td className={classes.center}>{item.price}</td>
                      <td className={classes.center}>{item.contact_number}</td>
                      <td className={classes.center}>{item.status}</td>{" "}
                    
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">{message || "No data available"}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {show && (
            <div
              style={{ background: "white", marginLeft: "5%", maxWidth: "90%" }}
            >
              <div
                colSpan="5"
                style={{ marginTop: "2rem", paddingTop: "2rem" }}
              >
                <div>
                  <div className={classes.divright}>
                    <img className={classes.imgwidth} src={Generate} />
                    <img className={classes.imgwidth} src={Email} />
                  </div>
                  <div className={classes.fieldsContainer}>
                    <div className={classes.fieldWrapper}>
                      <Grid xs={12}>
                        <div className={classes.displayinlineblock}>
                          <p className={classes.marginstyle}>Name</p>
                          <input
                            className={classes.inputstyle}
                            type="text"
                          ></input>
                        </div>
                      </Grid>
                      <Grid xs={12}>
                        <div className={classes.displayinlineblock}>
                          <p className={classes.marginstyle}>
                            Configured Price
                          </p>
                          <input
                            className={classes.inputstyle}
                            type="text"
                          ></input>
                        </div>
                      </Grid>
                    </div>
                    <div className={classes.fieldWrapper}>
                      <Grid xs={12}>
                        <div className={classes.displayinlineblock}>
                          <p className={classes.marginstyle}>Address</p>
                          <input
                            className={classes.inputstyle}
                            type="text"
                          ></input>
                        </div>
                      </Grid>
                      <Grid xs={12}>
                        <div className={classes.displayinlineblock}>
                          <p className={classes.marginstyle}>Bathroom Model</p>
                          <input
                            className={classes.inputstyle}
                            type="text"
                          ></input>
                        </div>
                      </Grid>
                    </div>

                    <div className={classes.fieldWrapper}>
                      <Grid xs={12}>
                        <div className={classes.smallFieldBox}>
                          <div className={classes.statestylelabel}>
                            <p className={classes.marginstyle}>State</p>
                            <select
                              className={classes.statestyle}
                              name="state"
                              id="state"
                            >
                              <option value="volvo">TX</option>
                              <option value="saab">Saab</option>
                              <option value="mercedes">Mercedes</option>
                              <option value="audi">Audi</option>
                            </select>
                          </div>
                          <div
                            className={classes.statestylelabel}
                            style={{ marginTop: "15px" }}
                          >
                            <p className={classes.marginstyle}>Zip Code</p>
                            <input
                              className={classes.zipcodestyle}
                              type="text"
                            ></input>
                          </div>
                        </div>
                      </Grid>
                      <Grid xs={12}>
                        <p className={classes.textareamargin}>Notes</p>
                        <textarea
                          rows="8"
                          className={classes.textareastyle}
                        ></textarea>
                      </Grid>
                    </div>
                  </div>
                  <div className={classes.divright}>
                    <button className={classes.btnupdate}>UPDATE</button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </GridContainer>
      {/* Edit Price Modal */}
      <div className={classes.textaligncenter}>
        <Dialog
          className={classes.textaligncenter}
          open={openpopup}
          onClose={handleClosepoup}
          aria-labelledby="edit-price-dialog-title"
          aria-describedby="edit-price-dialog-description"
        >
          <DialogTitle id="edit-price-dialog-title">Edit Price</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can add a discount to make your client happy!
            </DialogContentText>

            <div className={classes.textaligncenter}>
              {/* {console.log("this is my price",customerdetail)} */}
              <p className="ppprice">Total Price: {customerdetail?.[0]?.price}</p>
              <input
                value={discount}
                onChange={handleDiscountChange}
                className={classes.registertextfield}
                type="number"
                placeholder="Discount"
              />
              <p className="ppprice">
                Final Price:{" "}
                {parseFloat(price) - (parseFloat(discount) || 0) > 0
                  ? parseFloat(price) - parseFloat(discount)
                  : 0}
              </p>
            </div>
          </DialogContent>

          {/* Dialog actions with Cancel and Save buttons */}
          <DialogActions className={classes.textaligncenter}>
            <div className={classes.textalignbtn}>
              <button onClick={handleClosepoup} className={classes.cancelbtn}>
                Cancel
              </button>
              <button onClick={savediscount} className={classes.sendbtn}>
                Save
              </button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
      {/* Assign Sales Modal */}
      <Dialog open={openassignval} onClose={handleCloseassign}>
        <DialogTitle>Assign Sales Rep</DialogTitle>
        <DialogContent>
          <select
            value={selectsales}
            onChange={(e) => setSelectsales(e.target.value)}
          >
            {allusers.map((user, index) => (
              <option key={index} value={user.id}>
                {user.name}
              </option>
            ))}
          </select>
        </DialogContent>
        <DialogActions>
          <button onClick={handleCloseassign}>Cancel</button>
          <button onClick={Assignrole}>Assign</button>
        </DialogActions>
      </Dialog>
      {/* Customer Detail Modal */}
      <Dialog open={openpopupdetail} onClose={handleClosepoupdetail}>
        <DialogTitle>Customer Detail</DialogTitle>
        <DialogContent>
          <div>
            {customerdetail && customerdetail.length > 0 ? (
              customerdetail.map((customer, index) => (
                <div key={index}>
                  <p className={classes.userdetailtit}>
                    Customer Name:{" "}
                    <span className={classes.spanuserdetail}>
                      {customer.name || "N/A"}
                    </span>
                  </p>
                  <p className={classes.userdetailtit}>
                    Customer Email:{" "}
                    <span className={classes.spanuserdetail}>
                      {customer.email || "N/A"}
                    </span>
                  </p>
                  <p className={classes.userdetailtit}>
                    Price:{" "}
                    <span className={classes.spanuserdetail}>
                      {customer.price || "N/A"}
                    </span>
                  </p>
                  <p className={classes.userdetailtit}>
                    Customer Phone:{" "}
                    <span className={classes.spanuserdetail}>
                      {customer.contact_number || "N/A"}
                    </span>
                  </p>
                </div>
              ))
            ) : (
              <p>No customer details available</p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClosepoupdetail} className={classes.sendbtn}>
            Close
          </button>
        </DialogActions>
      </Dialog>


      {/* Alert Box */}
      <Dialog open={openalert} onClose={handleClosepoup}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClosepoup}>Close</button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
