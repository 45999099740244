/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";
import userprofilepic from "assets/img/Ellipse 1.png";  
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(styles);

// var drawerwidth = 260; 
// var margintop = 70;
var drawerwidth;
var margintop;
export default function Sidebar(props) {
  drawerwidth = 260;
  const classes = useStyles();
  const history = useHistory();
  let location = useLocation();
  console.log(drawerwidth);
  const [userinfo, setUserinfo] = React.useState("");

  
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return location.pathname === routeName;
  }

  React.useEffect(() => {
    if(localStorage.getItem("logininfo")){
     setUserinfo (JSON.parse(localStorage.getItem("logininfo")))
    }
     console.log(userinfo);
  }, [])

  const logout = ()=>{
    localStorage.setItem('logininfo', "");
    localStorage.setItem('login', false);
    // history.push('/admin/login'); 
    window.open("/admin/login","_self");
  }
  const { color, logo, image, logoText, routes } = props;
  var links = (
    <>
    <div className={classes.meindiv}>
      <img className={classes.userprofileui} src={userprofilepic} />
      <p className={classes.username}>{userinfo.user?.name}</p>
      <small className={classes.useremail}>{userinfo.user?.email}</small>
    </div> 
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
        // console.log(prop.path);
        if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true,
          });
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path),
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });
        return (
          <>
            {((!(prop.name == "Login") && !(prop.name == "Signup")
            && !(prop.name == "Logout") && !(prop.name == "Contractform") && !(prop.name == "Xced Layout1") && !(prop.name == "Reports")) 
            && ((!(prop.name == "Appointment") && (userinfo.user?.role == "admin")) || (((prop.name == "Appointment") || (prop.name == "Dashboard")) && !(userinfo.user?.role == "admin"))))  &&(
            <>
              <NavLink
                to={prop.layout + prop.path}
                className={activePro + classes.item}
                activeClassName="active"
                key={key}
              > 
                <ListItem button className={classes.itemLink + listItemClasses}>
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(classes.itemIcon, whiteFontClasses, {
                        [classes.itemIconRTL]: props.rtlActive,
                      })}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(classes.itemIcon, whiteFontClasses, {
                        [classes.itemIconRTL]: props.rtlActive,
                      })}
                    />
                  )}
                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    className={classNames(classes.itemText, whiteFontClasses, {
                      [classes.itemTextRTL]: props.rtlActive,
                    })}
                    disableTypography={true}
                  />
                </ListItem>
              </NavLink>
            </>)}
            {(prop.name == "Logout") && (
            <button onClick={logout} className={classes.logoutbtn}>Logout</button>)}
          </>
        );
      })}
    </List>
    </>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="http://xced.instantsolutionslab.site/admin/dashboard"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      {console.log(props)}
      {/* {(props.location.pathname == "/admin/XcedLayout1") &&
        (drawerwidth = 260)} */}
      {(!(props.location.pathname == "/admin/login")&& !(props.location.pathname == "/admin/signup") &&
      !(props.location.pathname == "/admin/contractform") &&
      !(props.location.pathname == "/admin/XcedLayout1")) ? (
        <>
          <Hidden mdUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={props.rtlActive ? "left" : "right"}
              open={props.open}
              classes={{
                paper: classNames(classes.drawerPaper, {
                  [classes.drawerPaperRTL]: props.rtlActive,
                }),
              }}
              onClose={props.handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {brand}
              <div className={classes.sidebarWrapper}>
                {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
                {links}
              </div>
              {image !== undefined ? (
                <div
                  className={classes.background}
                  style={{ backgroundImage: "url(" + image + ")" }}
                />
              ) : null}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              anchor={props.rtlActive ? "right" : "left"}
              variant="permanent"
              open
              classes={{
                paper: classNames(classes.drawerPaper, {
                  [classes.drawerPaperRTL]: props.rtlActive,
                }),
              }}
            >
              {brand}
              <div className={classes.sidebarWrapper}>{links}</div>
              {image !== undefined ? (
                <div
                  className={classes.background}
                  style={{ backgroundImage: "url(" + image + ")" }}
                />
              ) : null}
            </Drawer>
          </Hidden>
        </> 
      ):<>
        {(props.location.pathname == "/admin/contractform") &&(
          <>
            {/* <Hidden smDown implementation="css">
            <Drawer
              anchor={props.rtlActive ? "right" : "left"}
              variant="permanent"
              open
              classes={{
                paper: classNames(classes.drawerPaper, {
                  [classes.drawerPaperRTL]: props.rtlActive,
                }),
              }}
            >
              <div className={classes.divcontract}>  
               <img className={classes.contractimage} src={logo} />
                <input
                  className={classes.contractfields}
                  type="text"
                  placeholder="Name"
                ></input>
                <input
                  className={classes.contractfields}
                  type="text"
                  placeholder="Billing Address"
                ></input>
                <select className={classes.selectcontract} name="State" id="State">
                  <option value="State">State</option>
                  <option value="saab">Saab</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="audi">Audi</option>
                </select>
                <input
                  className={classes.smallfieldscontract}
                  type="text"
                  placeholder="Zip Code"
                ></input>
                <input
                  className={classes.contractfields}
                  type="text"
                  placeholder="Credit Card Number"
                ></input>
                <input
                  className={classes.smallfieldscontract}
                  type="text"
                  placeholder="XX / XX"
                ></input>
                <input
                  className={classes.smallfieldscontract}
                  type="text"
                  placeholder="CVC"
                ></input>
                <input
                  className={classes.contractfields}
                  type="text"
                  placeholder="Total Charged"
                ></input>
                <button className={classes.contractbtn}>PLACE ORDER</button>
               </div>
            </Drawer>
          </Hidden>   */}
          </>
        )}
      </>}
    </div>
  );
}


Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};

// export {drawerwidth,margintop};